import React from 'react';

function Rocket() {
  return (
    <>
    <div id="i-rocket" className='svgfg'>
    <svg width="768" height="768" viewBox="0 0 768 768" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Frame 2" clip-path="url(#clip0_248_590)">
<g id="Group 139">
<g id="Group 42">
<g id="Calque 12" className='float'>
<path id="Vector" d="M111.893 563.823L67.2084 533.879L67.1953 533.892L111.893 563.837L156.094 593.441H156.107L111.893 563.823Z" fill="black"/>
<path id="Vector_2" d="M122.934 513.023L125.092 509.989L125.184 510.055L125.38 509.754L141.09 520.28L111.894 563.823L67.209 533.879L76.902 519.417L81.4019 521.378L88.5441 511.859L93.2402 513.611L96.2226 510.316L101.115 511.977L103.888 508.76L108.924 510.434" fill="#9F94FC"/>
<path id="Vector_3" d="M162.373 575.592L168.298 565.406L165.067 561.692L166.807 557.717L163.576 553.794L165.342 550.041L162.045 545.988L163.825 542.405L154.21 533.971L156.316 530.623L141.011 520.372L111.814 563.915L156.028 593.532L165.734 579.044L162.373 575.592Z" fill="#B9D5FD"/>
<path id="Vector_4" d="M81.7422 608.805L97.0077 619.031L97.1254 618.861L99.8724 614.755L110.507 621.881L113.712 617.972L118.173 620.482L121.574 616.546L125.825 618.979L129.357 615.043L133.451 617.501L141.248 607.105L146.035 609.538L156.095 593.441L111.894 563.837L81.7422 608.805Z" fill="#9F94FC"/>
<path id="Vector_5" d="M58.3259 567.563L53.5645 563.98L60.6413 553.415L56.1414 550.407L67.2341 533.866L111.919 563.81L81.7671 608.792L66.0176 598.24L68.6599 594.304L68.6076 594.278L68.5814 594.317L58.2475 586.851L60.6805 582.483L56.6123 579.227L59.0192 574.677L55.108 571.578L58.3259 567.563Z" fill="#B9D5FD"/>
<path id="Vector_6" d="M157.817 529.527L126.109 508.284L122.927 513.031L154.635 534.274L157.817 529.527Z" fill="black"/>
<path id="Vector_7" d="M100.395 615.288L68.6875 594.046L65.505 598.792L97.2129 620.035L100.395 615.288Z" fill="black"/>
<path id="Vector_8" d="M76.6549 518.201L55.4043 549.897L60.1529 553.079L81.4035 521.383L76.6549 518.201Z" fill="black"/>
<path id="Vector_9" d="M122.876 513.006L112.053 505.756L108.921 510.426L119.744 517.677L122.876 513.006Z" fill="black"/>
<path id="Vector_10" d="M71.5123 589.729L60.6895 582.478L57.5579 587.149L68.3808 594.4L71.5123 589.729Z" fill="black"/>
<path id="Vector_11" d="M88.5621 510.471L81.3086 521.29L85.9811 524.421L93.2346 513.602L88.5621 510.471Z" fill="black"/>
<path id="Vector_12" d="M60.0347 552.948L52.7812 563.767L57.4538 566.897L64.7073 556.078L60.0347 552.948Z" fill="black"/>
<path id="Vector_13" d="M108.913 510.434L104.24 507.304L101.109 511.975L105.781 515.105L108.913 510.434Z" fill="black"/>
<path id="Vector_14" d="M101.089 512.011L96.416 508.881L93.2845 513.551L97.957 516.682L101.089 512.011Z" fill="black"/>
<path id="Vector_15" d="M62.151 569.997L57.4785 566.866L54.347 571.537L59.0195 574.667L62.151 569.997Z" fill="black"/>
<path id="Vector_16" d="M63.6959 577.822L59.0234 574.692L55.8919 579.362L60.5644 582.493L63.6959 577.822Z" fill="black"/>
<path id="Vector_17" d="M123.042 513.061L154.75 534.303L157.932 529.557L126.225 508.314L123.042 513.061Z" fill="black"/>
<path id="Vector_18" d="M68.0428 598.465L99.7676 619.862L102.969 615.119L71.2439 593.722L68.0428 598.465Z" fill="black"/>
<path id="Vector_19" d="M162.803 575.877L141.553 607.573L146.301 610.755L167.552 579.059L162.803 575.877Z" fill="black"/>
<path id="Vector_20" d="M151.646 539.034L162.469 546.285L165.6 541.614L154.777 534.364L151.646 539.034Z" fill="black"/>
<path id="Vector_21" d="M100.187 615.697L111.01 622.948L114.141 618.277L103.318 611.027L100.187 615.697Z" fill="black"/>
<path id="Vector_22" d="M166.032 564.477L158.736 575.287L163.405 578.435L170.701 567.626L166.032 564.477Z" fill="black"/>
<path id="Vector_23" d="M137.439 607.123L130.143 617.933L134.811 621.081L142.107 610.272L137.439 607.123Z" fill="black"/>
<path id="Vector_24" d="M161.773 550.434L166.441 553.583L169.591 548.916L164.923 545.767L161.773 550.434Z" fill="black"/>
<path id="Vector_25" d="M160.851 558.798L165.523 561.928L168.655 557.257L163.982 554.127L160.851 558.798Z" fill="black"/>
<path id="Vector_26" d="M122.013 616.841L126.686 619.972L129.817 615.301L125.145 612.17L122.013 616.841Z" fill="black"/>
<path id="Vector_27" d="M114.183 618.383L118.855 621.513L121.987 616.842L117.314 613.712L114.183 618.383Z" fill="black"/>
<path id="Vector_28" d="M103.649 540.345L97.6621 536.334L93.6494 542.319L99.6368 546.33L103.649 540.345Z" fill="black"/>
<path id="Vector_29" d="M142.771 566.543L136.783 562.532L132.77 568.517L138.758 572.528L142.771 566.543Z" fill="black"/>
<path id="Vector_30" d="M95.9867 587.57C89.2714 583.058 84.6202 576.068 83.0533 568.133C81.4863 560.199 83.1315 551.966 87.6279 545.242L91.4345 547.792C87.7254 553.505 86.4135 560.447 87.7823 567.119C89.1511 573.79 93.0908 579.656 98.75 583.448C104.409 587.24 111.334 588.654 118.027 587.385C124.721 586.115 130.646 582.265 134.523 576.664L138.33 579.279C133.806 585.982 126.809 590.618 118.872 592.172C110.935 593.726 102.705 592.071 95.9867 587.57Z" fill="black"/>
</g>
</g>
<g id="Group 43" className='float-2'>
<g id="Calque 13">
<path id="Vector_31" d="M622.772 446.792L622.837 444.269H622.916V444.02H635.775V479.679H599.174V467.832L602.47 467.243L602.902 459.149L606.211 458.365L606.643 455.37L610.044 454.468L610.384 451.591L613.864 450.637" fill="#9F94FC"/>
<path id="Vector_32" d="M668.765 467.217L668.255 459.228L665.011 458.352L664.501 455.436L661.191 454.455L660.759 451.657L657.358 450.624L657.005 447.917L648.385 446.792L648.306 444.099H635.762V479.757H671.97V467.897L668.765 467.217Z" fill="#B9D5FD"/>
<path id="Vector_33" d="M635.775 516.514H648.281V516.37V513.01H656.993L657.32 509.597L660.799 509.322L661.218 505.805L664.54 505.569L665.051 502.013L668.295 501.856L668.766 493.029L672.389 492.585L671.984 479.679H635.775V516.514Z" fill="#9F94FC"/>
<path id="Vector_34" d="M606.918 502.065L602.876 501.843V493.199H599.188V479.666H635.788V516.488H622.89V513.271H622.851V513.31L614.178 513.01L613.904 509.61L610.385 509.309L610.006 505.831L606.631 505.556L606.918 502.065Z" fill="#B9D5FD"/>
<path id="Vector_35" d="M648.739 442.909H622.773V446.792H648.739V442.909Z" fill="black"/>
<path id="Vector_36" d="M648.778 513.114H622.812V516.998H648.778V513.114Z" fill="black"/>
<path id="Vector_37" d="M598.586 467.243V493.199H602.471V467.243H598.586Z" fill="black"/>
<path id="Vector_38" d="M622.721 446.805H613.865V450.637H622.721V446.805Z" fill="black"/>
<path id="Vector_39" d="M622.76 509.61H613.904V513.441H622.76V509.61Z" fill="black"/>
<path id="Vector_40" d="M602.379 458.378V467.23H606.212V458.378H602.379Z" fill="black"/>
<path id="Vector_41" d="M602.34 493.173V502.026H606.173V493.173H602.34Z" fill="black"/>
<path id="Vector_42" d="M613.878 450.637H610.045V454.468H613.878V450.637Z" fill="black"/>
<path id="Vector_43" d="M610.046 454.494H606.213V458.325H610.046V454.494Z" fill="black"/>
<path id="Vector_44" d="M610.018 502H606.186V505.831H610.018V502Z" fill="black"/>
<path id="Vector_45" d="M613.852 505.831H610.02V509.662H613.852V505.831Z" fill="black"/>
<path id="Vector_46" d="M622.851 446.779H648.816V442.896H622.851V446.779Z" fill="black"/>
<path id="Vector_47" d="M622.761 516.985H648.766V513.101H622.761V516.985Z" fill="black"/>
<path id="Vector_48" d="M669.105 467.23V493.186H672.991V467.23H669.105Z" fill="black"/>
<path id="Vector_49" d="M648.857 450.624H657.713V446.792H648.857V450.624Z" fill="black"/>
<path id="Vector_50" d="M648.816 513.415H657.672V509.584H648.816V513.415Z" fill="black"/>
<path id="Vector_51" d="M665.365 458.352V467.204H669.198V458.352H665.365Z" fill="black"/>
<path id="Vector_52" d="M665.404 493.16V502.013H669.237V493.16H665.404Z" fill="black"/>
<path id="Vector_53" d="M657.699 454.442H661.531V450.61H657.699V454.442Z" fill="black"/>
<path id="Vector_54" d="M661.533 458.312H665.365V454.481H661.533V458.312Z" fill="black"/>
<path id="Vector_55" d="M661.558 505.805H665.391V501.973H661.558V505.805Z" fill="black"/>
<path id="Vector_56" d="M657.738 509.649H661.57V505.818H657.738V509.649Z" fill="black"/>
<path id="Vector_57" d="M622.235 469.532H617.33V474.435H622.235V469.532Z" fill="black"/>
<path id="Vector_58" d="M654.259 469.532H649.354V474.435H654.259V469.532Z" fill="black"/>
<path id="Vector_59" d="M635.789 499.123C630.285 499.116 625.009 496.927 621.117 493.037C617.226 489.147 615.036 483.873 615.029 478.371H618.143C618.143 483.053 620.003 487.543 623.315 490.853C626.627 494.164 631.118 496.024 635.802 496.024C640.485 496.024 644.977 494.164 648.289 490.853C651.601 487.543 653.461 483.053 653.461 478.371H656.575C656.568 483.877 654.375 489.155 650.477 493.046C646.58 496.937 641.297 499.123 635.789 499.123Z" fill="black"/>
</g>
</g>
<g id="Group 45">
<g id="Calque 11">
<path id="Vector_60" d="M698.171 251.54H716.34V233.482H734.444V215.319H716.34V197.182H698.171V215.319H680.027V233.482H698.171V251.54ZM698.171 233.377V215.345H716.275V233.377H698.171Z" fill="#9F94FC"/>
</g>
</g>
<g id="Group 46">
<g id="Calque 10">
<path id="Vector_61" d="M614.112 628.969H623.399V619.737H632.661V610.453H623.399V601.169H614.112V610.453H604.824V619.737H614.112V628.969ZM614.112 619.685V610.453H623.373V619.685H614.112Z" fill="#9F94FC"/>
</g>
</g>
<g id="Group 48" className='float-3 noMobile'>
<g id="Calque 4">
<path id="Vector_62" d="M573.547 36.9608C573.753 36.7298 574.035 36.5794 574.341 36.5363C574.648 36.4932 574.96 36.5602 575.222 36.7254L620.874 66.7351C621.131 66.9129 621.316 67.1768 621.395 67.479C621.474 67.7812 621.443 68.1018 621.306 68.3826L591.468 126.506L530.877 86.65L573.547 36.9608Z" fill="#B2D6FF"/>
<path id="Vector_63" d="M622.654 68.9449C622.98 68.3145 623.06 67.5855 622.879 66.8993C622.697 66.2132 622.268 65.6188 621.673 65.2313L612.242 59.0332L614.858 54.9535L612.83 53.6459L610.214 57.7125L587.021 42.4527L589.703 38.373L594.412 41.472L595.72 39.4322L591.011 36.3331L589.703 38.2945L587.676 36.9869L585.02 41.1059L576.06 35.2086C575.473 34.827 574.768 34.671 574.075 34.7696C573.382 34.8682 572.749 35.2147 572.292 35.7447L528.432 86.8461L592.045 128.69L622.654 68.9449ZM573.469 36.7908C573.675 36.5631 573.954 36.4148 574.257 36.3718C574.561 36.3287 574.87 36.3937 575.131 36.5554L620.784 66.5651C621.043 66.741 621.23 67.004 621.312 67.3064C621.393 67.6087 621.364 67.9303 621.228 68.2127L591.39 126.336L530.799 86.5062L573.469 36.7908Z" fill="black"/>
<path id="Vector_64" d="M606.146 66.212C605.646 65.8843 605.037 65.7677 604.451 65.8877C603.865 66.0078 603.351 66.3547 603.02 66.8528C602.816 67.1629 602.692 67.519 602.66 67.8888C602.628 68.2586 602.689 68.6305 602.837 68.9711L600.011 73.2731L595.302 70.1741L593.994 72.214L598.716 75.313L600.024 73.3516L602.052 74.6592L604.904 70.3179C605.395 70.3354 605.879 70.1914 606.281 69.908C606.682 69.6246 606.98 69.2175 607.129 68.7488C607.277 68.2802 607.268 67.7758 607.102 67.313C606.937 66.8501 606.624 66.4542 606.212 66.1859L606.146 66.212ZM605.27 67.5196C605.416 67.615 605.517 67.7643 605.553 67.9348C605.589 68.1052 605.555 68.2828 605.46 68.4284C605.364 68.5741 605.215 68.6759 605.044 68.7114C604.874 68.747 604.696 68.7134 604.551 68.618C604.478 68.5708 604.416 68.5098 604.368 68.4386C604.319 68.3674 604.285 68.2873 604.267 68.2029C604.25 68.1185 604.249 68.0315 604.265 67.9468C604.281 67.8621 604.314 67.7814 604.361 67.7092C604.408 67.6371 604.469 67.575 604.54 67.5265C604.612 67.4779 604.692 67.4438 604.776 67.4262C604.861 67.4086 604.948 67.4078 605.032 67.4238C605.117 67.4399 605.198 67.4724 605.27 67.5196Z" fill="black"/>
<path id="Vector_65" d="M597.167 37.3977L595.809 39.4626L609.634 48.5514L610.993 46.4865L597.167 37.3977Z" fill="black"/>
<path id="Vector_66" d="M610.756 45.2288L609.41 47.2828L614.157 50.3897L615.502 48.3357L610.756 45.2288Z" fill="black"/>
<path id="Vector_67" d="M580.132 63.1411L578.773 65.206L592.599 74.2948L593.958 72.2299L580.132 63.1411Z" fill="black"/>
<path id="Vector_68" d="M576.852 58.0406L575.508 60.0836L580.229 63.1875L581.573 61.1444L576.852 58.0406Z" fill="black"/>
<path id="Vector_69" d="M576.832 58.0264L579.657 53.7374C580.146 53.7342 580.621 53.5727 581.01 53.2771C581.4 52.9815 581.683 52.5678 581.818 52.0979C581.952 51.6279 581.931 51.127 581.757 50.6702C581.583 50.2134 581.266 49.8252 580.852 49.5638C580.439 49.3024 579.952 49.1818 579.465 49.2201C578.977 49.2584 578.515 49.4535 578.148 49.7762C577.781 50.0989 577.528 50.5318 577.427 51.0102C577.327 51.4886 577.384 51.9866 577.59 52.4298L574.791 56.6926L576.832 58.0264ZM579.997 50.9391C580.104 51.0116 580.187 51.1139 580.235 51.2331C580.284 51.3524 580.296 51.4834 580.27 51.6096C580.245 51.7358 580.182 51.8516 580.091 51.9426C580 52.0335 579.884 52.0955 579.758 52.1207C579.631 52.146 579.5 52.1334 579.381 52.0845C579.262 52.0356 579.16 51.9527 579.088 51.846C579.015 51.7394 578.976 51.6138 578.975 51.485C578.974 51.3562 579.011 51.23 579.082 51.1222C579.18 50.978 579.331 50.878 579.502 50.8438C579.673 50.8096 579.851 50.8438 579.997 50.9391Z" fill="black"/>
</g>
</g>
<g id="Group 49" className='float-4 noMobile'>
<g id="Calque 5">
<path id="Vector_70" d="M747.304 -3.50976C747.632 -3.89482 748.09 -4.14709 748.591 -4.2193C749.091 -4.29151 749.601 -4.17872 750.025 -3.90205L824.587 45.1072C825.016 45.387 825.326 45.8162 825.456 46.3115C825.587 46.8069 825.529 47.333 825.293 47.7878L776.579 142.707L677.635 77.6667L747.304 -3.50976Z" fill="#B2D6FF"/>
<path id="Vector_71" d="M827.477 48.7947C828.007 47.7647 828.137 46.5754 827.842 45.4554C827.548 44.3354 826.849 43.3638 825.882 42.7274L810.472 32.6065L814.854 25.9638L811.519 23.7801L807.15 30.4227L769.293 5.57813L773.675 -1.0776L781.367 3.98285L783.551 0.648444L775.847 -4.41201L773.741 -1.20837L770.431 -3.379L765.971 3.39443L751.333 -6.22959C750.378 -6.85548 749.227 -7.11302 748.096 -6.95425C746.965 -6.79548 745.931 -6.23124 745.185 -5.36657L673.566 78.0721L777.43 146.342L827.477 48.7947ZM747.225 -3.7059C747.557 -4.08651 748.015 -4.33451 748.515 -4.40419C749.015 -4.47388 749.523 -4.36056 749.946 -4.08511L824.508 44.9111C824.938 45.1932 825.249 45.6247 825.379 46.1222C825.509 46.6198 825.451 47.1479 825.214 47.6048L776.501 142.524L677.491 77.4967L747.225 -3.7059Z" fill="black"/>
<path id="Vector_72" d="M758.032 39.3083L755.811 42.6843L778.38 57.5209L780.601 54.1449L758.032 39.3083Z" fill="black"/>
<path id="Vector_73" d="M752.667 30.9911L750.475 34.3234L758.18 39.3887L760.372 36.0564L752.667 30.9911Z" fill="black"/>
<path id="Vector_74" d="M752.655 30.972L757.272 23.9632C757.876 23.9601 758.47 23.8067 759 23.5167C759.53 23.2268 759.979 22.8095 760.307 22.3025C760.843 21.4875 761.033 20.4931 760.836 19.5379C760.638 18.5828 760.069 17.7452 759.254 17.2094C758.85 16.9441 758.398 16.7608 757.924 16.6701C757.449 16.5795 756.961 16.5831 756.488 16.6809C755.533 16.8783 754.695 17.447 754.159 18.262C753.813 18.7851 753.608 19.3887 753.565 20.0142C753.521 20.6397 753.64 21.2658 753.91 21.8318L749.332 28.7883L752.655 30.972ZM757.887 19.3996C758.121 19.5543 758.284 19.7952 758.34 20.0697C758.396 20.3442 758.342 20.6299 758.188 20.8641C758.111 20.9809 758.013 21.0815 757.897 21.1601C757.782 21.2387 757.652 21.2939 757.516 21.3224C757.379 21.351 757.238 21.3523 757.101 21.3264C756.964 21.3005 756.833 21.2478 756.716 21.1714C756.599 21.095 756.499 20.9963 756.42 20.8811C756.341 20.7658 756.286 20.6361 756.258 20.4995C756.229 20.3629 756.228 20.2221 756.254 20.0849C756.28 19.9478 756.332 19.8171 756.409 19.7004C756.563 19.4809 756.794 19.3279 757.056 19.2721C757.318 19.2162 757.592 19.2618 757.822 19.3996H757.887Z" fill="black"/>
</g>
</g>
<g id="Group 50">
<g id="Calque 6">
<path id="Vector_75" d="M64.725 717.899H74.0125V708.668H83.2739V699.384H74.0125V690.113H64.725V699.384H55.4375V708.668H64.725V717.899ZM64.725 708.615V699.462H73.9864V708.615H64.725Z" fill="#9F94FC"/>
</g>
</g>
<g id="Group 51">
<g id="Calque 7">
<path id="Vector_76" d="M477.428 145.492H486.716V136.248H495.977V126.964H486.716V117.693H477.428V126.964H468.141V136.248H477.428V145.492ZM477.428 136.208V126.977H486.689V136.208H477.428Z" fill="#9F94FC"/>
</g>
</g>
<g id="Group 48_2">
<g id="Calque 2">
<path id="Vector_77" d="M335.277 419.488C335.277 419.488 370.74 337.775 425.889 291.002C431.802 290.021 445.105 283.614 503.708 319.063C562.311 354.513 564.273 377.644 564.273 377.644C564.273 377.644 531.767 470.197 491.386 514.499C474.642 479.05 361.335 413.094 335.277 419.488Z" fill="#9F94FC"/>
<path id="Vector_78" d="M491.215 515.663L490.797 514.773C484.91 502.299 462.293 479.22 416.51 451.864C379.883 429.975 347.311 417.213 335.408 420.129L334.1 420.442L334.636 419.226C334.989 418.403 370.779 336.847 425.418 290.505L425.562 290.387H425.732C426.138 290.322 426.569 290.23 427.04 290.126C433.672 288.714 449.212 285.418 503.956 318.527C562.18 353.741 564.77 376.65 564.848 377.618V377.749V377.893C564.521 378.808 531.845 471.073 491.83 514.97L491.215 515.663ZM339.358 418.403C355.696 418.403 389.171 434.002 417.177 450.74C446.91 468.51 481.81 494.139 491.556 513.348C530.354 470.197 562.128 381.737 563.606 377.566C563.475 376.664 562.376 371.028 555.312 361.875C548.249 352.721 533.415 337.801 503.368 319.626C449.055 286.778 433.868 289.995 427.38 291.368C426.949 291.473 426.556 291.551 426.177 291.617C375.383 334.768 340.941 408.543 336.389 418.612C337.372 418.465 338.364 418.395 339.358 418.403Z" fill="#1D1D1B"/>
<path id="Vector_79" d="M425.89 291.002C425.89 291.002 422.698 289.276 428.363 285.34C434.027 281.404 445.59 272.787 445.59 272.787C450.689 271.466 456.05 271.552 461.104 273.035C469.725 275.494 530.303 297.396 568.709 341.711C571.168 345.15 571.913 346.876 571.913 351.309C571.913 355.742 570.919 371.002 564.274 377.644C557.132 355.86 527.83 331.734 503.709 319.063C479.588 306.393 453.844 286.321 425.89 291.002Z" fill="#FB7243"/>
<path id="Vector_80" d="M563.986 378.86L563.659 377.854C556.752 356.788 528.118 332.623 503.394 319.639C498.594 317.115 493.832 314.408 488.783 311.44C468.808 299.881 448.153 287.903 425.994 291.643H425.772L425.576 291.538C425.222 291.34 424.923 291.057 424.706 290.714C424.49 290.372 424.361 289.981 424.333 289.577C424.241 288.125 425.471 286.504 427.969 284.765C433.555 280.842 445.079 272.316 445.184 272.225L445.38 272.133C450.599 270.749 456.098 270.83 461.274 272.369C474.799 276.226 532.787 299.253 569.192 341.24C571.808 344.836 572.554 346.68 572.554 351.27C572.554 352.041 572.462 370.335 564.705 378.063L563.986 378.86ZM434.379 289.655C453.726 289.655 471.843 300.116 489.437 310.315C494.473 313.218 499.222 315.964 504.009 318.488C528.549 331.381 556.948 355.258 564.535 376.389C569.767 370.152 571.259 357.416 571.259 351.309C571.259 347.046 570.565 345.425 568.171 342.156C532.016 300.456 474.381 277.573 460.907 273.728C456.011 272.307 450.825 272.208 445.877 273.441C444.765 274.265 434.104 282.228 428.715 285.942C425.981 287.851 425.615 289.041 425.641 289.59C425.647 289.741 425.686 289.889 425.753 290.024C425.821 290.159 425.916 290.279 426.033 290.374C428.792 289.915 431.583 289.674 434.379 289.655Z" fill="#1D1D1B"/>
<path id="Vector_81" d="M450.023 271.309C450.023 271.309 453.346 251.695 483.262 253.957C501 238.815 540.884 238.449 545.685 240.293C549.146 241.804 552.378 243.794 555.286 246.203C555.286 246.203 569.675 259.868 571.167 303.804C574.868 308.236 581.147 331.12 571.533 344.418L568.707 341.711C568.707 341.711 519.091 285.405 450.023 271.309Z" fill="#9F94FC"/>
<path id="Vector_82" d="M571.626 345.412L568.251 342.182C567.728 341.58 517.941 285.772 449.894 271.95L449.279 271.832L449.384 271.205C449.384 270.996 453.112 251.185 483.054 253.29C500.766 238.423 540.742 237.704 545.922 239.691C549.458 241.212 552.754 243.238 555.706 245.706C556.334 246.295 570.305 260.09 571.822 303.568C575.982 308.916 581.58 331.629 572.071 344.797L571.626 345.412ZM450.81 270.799C518.962 284.948 568.709 340.717 569.206 341.28L571.443 343.411C580.325 330.152 573.85 308.027 570.671 304.183L570.527 304.013V303.79C569.088 260.705 555 246.779 554.83 246.635C551.982 244.286 548.821 242.345 545.438 240.868C540.362 238.92 500.962 239.678 483.682 254.415L483.473 254.585H483.211C471.177 253.696 461.719 256.337 455.885 262.235C453.497 264.615 451.751 267.562 450.81 270.799Z" fill="#1D1D1B"/>
<path id="Vector_83" d="M335.278 419.488C335.278 419.488 324.081 423.411 321.857 450.753C326.292 447.052 350.309 446.686 397.204 477.324C444.099 507.961 476.998 532.348 469.228 542.704C474.799 539.969 479.684 536.017 483.52 531.139C487.356 526.261 490.045 520.583 491.387 514.525C491.387 514.525 477.757 487.301 413.83 449.641C349.903 411.982 335.278 419.488 335.278 419.488Z" fill="#1C1C19"/>
<path id="Vector_84" d="M467.044 544.509L468.705 542.286C469.153 541.682 469.466 540.988 469.621 540.253C469.777 539.517 469.772 538.756 469.608 538.023C467.397 525.496 433.334 501.71 396.851 477.873C347.274 445.496 325.429 448.634 322.289 451.25L321.086 452.244L321.217 450.701C323.388 423.79 334.167 419.213 335.03 418.886C336.103 418.376 351.735 412.283 414.157 449.079C477.495 486.398 491.845 513.924 491.976 514.198L492.068 514.394V514.603C490.681 520.753 487.94 526.516 484.046 531.474C480.152 536.432 475.202 540.46 469.555 543.266L467.044 544.509ZM329.589 448.386C334.391 448.496 339.161 449.217 343.781 450.531C354.246 453.277 371.971 460.063 397.571 476.787C447.802 509.595 469.045 527.313 470.889 537.774C471.108 538.886 471.059 540.033 470.746 541.122C475.736 538.33 480.088 534.529 483.526 529.961C486.963 525.392 489.409 520.157 490.707 514.59C490.066 513.414 486.233 506.836 476.03 496.558C465.827 486.281 446.363 469.582 413.503 450.164C380.892 430.943 361.441 423.777 350.884 421.149C339.399 418.285 335.619 420.011 335.58 420.037H335.501C335.397 420.037 325.036 424.143 322.629 449.458C324.851 448.627 327.22 448.262 329.589 448.386Z" fill="#1D1D1B"/>
<path id="Vector_85" d="M307.088 585.502C307.088 585.502 298.219 579.605 298.219 568.895C298.219 558.186 294.531 503.515 321.857 450.753C326.671 447.798 350.635 446.686 401.272 479.913C451.908 513.139 475.14 531.969 469.293 542.678C463.014 551.91 426.125 602.828 367.732 617.212C368.831 612.779 357.019 603.547 339.294 591.726C321.569 579.906 317.802 579.971 307.088 585.502Z" fill="#9F94FC"/>
<path id="Vector_86" d="M366.763 618.153L367.024 617.107C368.333 611.955 346.919 597.702 338.861 592.328C321.359 580.664 317.801 580.703 307.388 586.091L307.048 586.261L306.721 586.051C306.355 585.803 297.564 579.827 297.564 568.895C297.564 568.019 297.564 566.856 297.564 565.417C297.224 549.007 296.165 499.069 321.333 450.452L321.411 450.282L321.568 450.191C326.696 447.039 351.079 446.137 401.676 479.363C450.847 511.661 476.238 531.354 469.854 542.992C463.039 553.021 426.242 603.495 367.822 617.892L366.763 618.153ZM318.036 581.279C323.269 581.279 328.946 584.155 339.581 591.243C359.451 604.476 368.058 611.968 368.359 616.401C425.784 601.847 462.058 552.158 468.69 542.312C474.563 531.668 448.284 511.57 400.917 480.462C379.373 466.313 359.778 456.611 344.224 452.387C331.496 448.935 324.603 449.994 322.353 451.21C297.434 499.487 298.48 549.085 298.807 565.391C298.807 566.829 298.807 568.006 298.807 568.895C298.807 578.049 305.557 583.593 307.074 584.744C311.378 582.573 314.583 581.279 318.036 581.279Z" fill="#1D1D1B"/>
<path id="Vector_87" d="M231.742 482.502C231.742 482.502 267.571 409.759 300.077 419.736C303.021 426.013 318.168 449.275 273.484 509.452C270.527 503.175 253.169 488.778 231.742 482.502Z" fill="#9F94FC"/>
<path id="Vector_88" d="M273.352 510.72L272.881 509.726C271.351 506.457 265.961 501.266 259.159 496.506C250.727 490.612 241.41 486.097 231.558 483.129L230.812 482.907L231.153 482.214C237.476 469.858 244.851 458.068 253.194 446.974C270.657 424.261 286.498 414.885 300.286 419.109L300.56 419.187L300.691 419.449C300.822 419.736 300.979 420.05 301.162 420.403C303.059 424.221 307.009 432.172 305.086 446.438C302.719 463.947 292.267 485.274 274.032 509.844L273.352 510.72ZM232.683 482.096C253.613 488.412 269.624 501.802 273.561 508.248C315.25 451.734 303.752 428.615 299.959 421.005L299.605 420.285C269.768 411.577 236.45 474.721 232.683 482.096Z" fill="#1D1D1B"/>
<path id="Vector_89" d="M296.872 516.957C296.872 516.957 257.969 529.759 281.607 545.515L278.153 547.477L270.305 560.775C270.305 560.775 283.111 561.756 298.86 573.08C299.855 529.262 300.836 518.932 300.836 518.932L296.872 516.957Z" fill="#9F94FC"/>
<path id="Vector_90" d="M299.462 574.388L298.455 573.655C283.032 562.567 270.357 561.481 270.226 561.468L269.18 561.39L277.682 547.006L280.377 545.476C275.21 541.802 272.869 537.958 273.405 534.022C274.818 523.652 295.773 516.643 296.663 516.356L296.925 516.264L301.503 518.553V518.997C301.503 519.102 300.509 529.85 299.528 573.106L299.462 574.388ZM271.351 560.291C274.634 560.762 285.295 562.907 298.22 571.89C299.07 534.27 299.933 521.756 300.116 519.363L296.82 517.716C294.426 518.539 275.89 525.248 274.7 534.231C274.216 537.853 276.649 541.475 281.973 545.019L282.849 545.607L278.637 548.013L271.351 560.291Z" fill="#1D1D1B"/>
<path id="Vector_91" d="M318.05 457.879L300.312 461.802L277.172 505.607L296.872 516.931L300.796 518.906C300.796 518.906 308.2 476.591 318.05 457.879Z" fill="#9F94FC"/>
<path id="Vector_92" d="M301.306 519.899L296.584 517.546L276.309 505.895L299.854 461.253L319.253 456.938L318.599 458.245C308.906 476.644 301.503 518.67 301.424 519.089L301.306 519.899ZM278.048 505.385L297.238 516.395L300.365 517.951C301.45 511.962 308.213 476.421 316.873 458.821L300.796 462.391L278.048 505.385Z" fill="#1D1D1B"/>
<path id="Vector_93" d="M221.368 498.376L220.818 495.604L228.759 483.835L231.715 482.528C231.715 482.528 261.265 489.537 273.456 509.478C273.897 510.658 273.96 511.946 273.639 513.165L267.544 523.508L265.137 524.816C265.137 524.816 265.699 520.2 244.207 506.352C222.715 492.505 221.368 498.376 221.368 498.376Z" fill="#9F94FC"/>
<path id="Vector_94" d="M264.367 526.006L264.537 524.698C264.537 524.698 264.236 519.965 243.96 506.862C229.022 497.199 224.143 497.448 222.743 497.997C222.457 498.091 222.212 498.28 222.05 498.533L221.343 501.357L220.166 495.46L228.368 483.273L231.69 481.809H231.913C232.201 481.874 261.855 489.118 274.047 509.059C274.578 510.409 274.665 511.893 274.295 513.296V513.427L268.108 523.888L264.367 526.006ZM224.13 496.532C227.308 496.532 233.286 498.494 244.654 505.829C261.07 516.434 264.785 521.769 265.583 523.861L267.074 523.051L273.039 512.943C273.285 511.878 273.216 510.766 272.843 509.739C261.528 491.25 234.437 483.927 231.769 483.247L229.153 484.385L221.461 495.813L221.71 497.121C221.857 497.036 222.01 496.962 222.167 496.898C222.787 496.629 223.454 496.483 224.13 496.467V496.532Z" fill="#1D1D1B"/>
<path id="Vector_95" d="M264.566 526.129C266.777 522.302 258.461 513.365 245.991 506.169C233.522 498.972 221.622 496.241 219.411 500.069C217.2 503.896 225.517 512.833 237.986 520.029C250.455 527.226 262.356 529.957 264.566 526.129Z" fill="#9F94FC"/>
<path id="Vector_96" d="M260.076 528.529C258.355 528.496 256.644 528.268 254.974 527.85C248.886 526.271 243.054 523.831 237.655 520.605C226.288 514.067 216.137 504.443 218.845 499.749C221.552 495.055 234.947 499.03 246.315 505.594C251.808 508.66 256.838 512.49 261.253 516.97C265.021 521.024 266.355 524.306 265.112 526.45C264.34 527.836 262.614 528.529 260.076 528.529ZM223.985 498.977C221.945 498.977 220.558 499.461 220.061 500.403C218.282 503.476 225.228 511.871 238.375 519.468C243.674 522.638 249.401 525.033 255.38 526.581C259.958 527.627 263.228 527.34 264.118 525.797C265.007 524.267 263.621 521.298 260.429 517.859C256.101 513.476 251.173 509.73 245.792 506.732C236.53 501.462 228.564 498.977 223.985 498.977Z" fill="#1D1D1B"/>
<path id="Vector_97" d="M260.137 533.01C262.347 529.183 254.031 520.246 241.562 513.049C229.092 505.853 217.192 503.122 214.981 506.95C212.771 510.777 221.087 519.714 233.556 526.91C246.025 534.107 257.926 536.838 260.137 533.01Z" fill="#9F94FC"/>
<path id="Vector_98" d="M255.64 535.421C253.92 535.387 252.208 535.159 250.539 534.741C244.452 533.157 238.621 530.718 233.22 527.497C221.852 520.958 211.701 511.334 214.409 506.64C217.117 501.946 230.512 505.921 241.879 512.498C247.377 515.552 252.408 519.379 256.818 523.861C260.585 527.915 261.932 531.197 260.742 533.355C259.905 534.728 258.191 535.421 255.64 535.421ZM219.511 505.869C217.47 505.869 216.097 506.352 215.586 507.294C213.807 510.367 220.753 518.762 233.9 526.359C239.199 529.53 244.926 531.926 250.905 533.472C255.47 534.518 258.754 534.231 259.63 532.701C260.52 531.158 259.133 528.19 255.941 524.751C251.616 520.364 246.687 516.617 241.304 513.623C232.095 508.353 224.128 505.869 219.511 505.869Z" fill="#1D1D1B"/>
<path id="Vector_99" d="M384.475 560.187C384.475 560.187 420.303 487.458 452.797 497.422C455.753 503.698 470.901 526.96 426.216 587.137C423.26 580.926 405.901 566.463 384.475 560.187Z" fill="#9F94FC"/>
<path id="Vector_100" d="M426.086 588.405L425.615 587.411C424.072 584.142 418.695 578.951 411.893 574.191C403.458 568.303 394.142 563.788 384.292 560.815L383.547 560.592L383.887 559.899C390.205 547.552 397.579 535.774 405.928 524.698C423.392 501.972 439.233 492.596 453.02 496.82L453.295 496.912L453.426 497.173L453.883 498.128C455.793 501.933 459.744 509.896 457.808 524.149C455.44 541.658 444.988 562.998 426.753 587.555L426.086 588.405ZM385.417 559.782C406.282 566.097 422.358 579.487 426.296 585.934C467.985 529.406 456.486 506.3 452.693 498.677L452.34 497.958C422.489 489.262 389.185 552.407 385.417 559.782Z" fill="#1D1D1B"/>
<path id="Vector_101" d="M374.128 576.061L373.578 573.289L381.518 561.521L384.475 560.213C384.475 560.213 414.025 567.222 426.216 587.163C426.656 588.344 426.72 589.632 426.399 590.85L420.303 601.194L417.897 602.501C417.897 602.501 418.459 597.885 397.032 584.038C375.606 570.19 374.128 576.061 374.128 576.061Z" fill="#9F94FC"/>
<path id="Vector_102" d="M417.099 603.639L417.256 602.331C417.256 602.331 416.955 597.598 396.679 584.495C381.95 574.976 377.058 575.094 375.566 575.59C375.398 575.632 375.24 575.707 375.1 575.81C374.961 575.913 374.844 576.043 374.755 576.192L373.971 578.441L372.885 573.106L381.087 560.906L384.409 559.441H384.632C384.919 559.52 414.574 566.751 426.765 586.692C427.296 588.042 427.384 589.526 427.014 590.929V591.059L420.827 601.52L417.099 603.639ZM376.861 574.165C380.04 574.165 386.018 576.113 397.385 583.449C413.802 594.054 417.517 599.389 418.315 601.481L419.806 600.683L425.758 590.563C426.018 589.5 425.955 588.385 425.575 587.359C414.26 568.882 387.169 561.56 384.501 560.88L381.884 562.004L374.206 573.433L374.455 574.741C374.602 574.656 374.755 574.582 374.912 574.518C375.534 574.278 376.195 574.158 376.861 574.165Z" fill="#1D1D1B"/>
<path id="Vector_103" d="M417.301 603.825C419.511 599.997 411.195 591.061 398.726 583.864C386.257 576.668 374.356 573.937 372.145 577.764C369.935 581.592 378.251 590.528 390.72 597.725C403.19 604.921 415.09 607.652 417.301 603.825Z" fill="#9F94FC"/>
<path id="Vector_104" d="M412.822 606.189C407.237 606.189 398.315 602.867 390.388 598.343C384.893 595.285 379.863 591.459 375.45 586.98C371.669 582.926 370.335 579.644 371.525 577.487C372.716 575.329 376.287 574.871 381.689 576.087C387.778 577.67 393.61 580.114 399.009 583.345C410.376 589.883 420.527 599.507 417.806 604.266C417.047 605.561 415.242 606.189 412.822 606.189ZM376.679 576.689C374.652 576.689 373.252 577.16 372.755 578.088C371.866 579.618 373.239 582.599 376.444 586.038C380.766 590.424 385.696 594.167 391.082 597.153C404.241 604.75 414.994 606.568 416.773 603.495C418.552 600.409 411.54 592.014 398.459 584.417C393.156 581.253 387.431 578.858 381.454 577.303C379.889 576.928 378.288 576.722 376.679 576.689Z" fill="#1D1D1B"/>
<path id="Vector_105" d="M416.313 608.66C418.523 604.815 410.161 595.864 397.634 588.667C385.108 581.471 373.161 578.754 370.95 582.599C368.739 586.444 377.102 595.395 389.629 602.592C402.155 609.788 414.102 612.505 416.313 608.66Z" fill="#9F94FC"/>
<path id="Vector_106" d="M408.818 613.132C403.22 613.132 394.311 609.798 386.384 605.286C380.887 602.233 375.856 598.406 371.446 593.923C367.665 589.87 366.331 586.588 367.522 584.43C368.712 582.272 372.283 581.815 377.672 583.031C383.763 584.609 389.596 587.053 394.992 590.288C406.372 596.826 416.51 606.45 413.802 611.145C413.03 612.504 411.238 613.132 408.818 613.132ZM372.676 583.619C370.635 583.619 369.248 584.09 368.751 585.018C367.862 586.561 369.235 589.53 372.44 592.969C376.765 597.355 381.694 601.102 387.078 604.096C400.237 611.694 410.99 613.498 412.769 610.425C414.548 607.352 407.536 598.958 394.455 591.36C389.157 588.188 383.43 585.792 377.45 584.247C375.887 583.862 374.285 583.651 372.676 583.619Z" fill="#1D1D1B"/>
<path id="Vector_107" d="M429.121 622.677C429.121 622.677 464.95 549.935 497.456 559.912C500.413 566.189 515.547 589.451 470.863 649.627C467.906 643.351 450.548 628.954 429.121 622.677Z" fill="#9F94FC"/>
<path id="Vector_108" d="M470.731 650.896L470.273 649.902C468.729 646.633 463.34 641.442 456.538 636.682C448.105 630.788 438.789 626.273 428.937 623.305L428.191 623.083L428.545 622.39C434.854 610.005 442.229 598.192 450.586 587.084C468.049 564.371 483.877 554.983 497.678 559.219L497.952 559.298L498.07 559.559C498.201 559.847 498.358 560.161 498.541 560.514C500.438 564.332 504.388 572.282 502.465 586.548C500.098 604.057 489.646 625.384 471.411 649.954L470.731 650.896ZM430.062 622.272C450.926 628.588 467.003 641.978 470.94 648.424C512.642 591.909 501.131 568.791 497.338 561.181C497.22 560.919 497.089 560.684 496.984 560.461C467.147 551.753 433.829 614.923 430.062 622.272Z" fill="#1D1D1B"/>
<path id="Vector_109" d="M418.787 638.552L418.225 635.78L426.165 624.011L429.121 622.704C429.121 622.704 458.671 629.713 470.863 649.654C471.303 650.834 471.367 652.123 471.046 653.341L464.95 663.684L462.556 664.992C462.556 664.992 463.106 660.376 441.627 646.528C420.148 632.681 418.787 638.552 418.787 638.552Z" fill="#9F94FC"/>
<path id="Vector_110" d="M461.758 666.116L461.902 664.874C461.902 664.874 461.601 660.141 441.326 647.038C426.609 637.519 421.704 637.637 420.213 638.134C419.546 638.356 419.415 638.722 419.415 638.735L418.617 640.984L417.531 635.636L425.733 623.449L429.069 621.985H429.278C429.579 621.985 459.233 629.294 471.425 649.235C471.956 650.585 472.043 652.069 471.674 653.472L471.608 653.603L465.421 664.063L461.758 666.116ZM421.508 636.643C424.687 636.643 430.665 638.604 442.045 645.94C458.449 656.545 462.177 661.88 462.975 663.972L464.466 663.161L470.431 653.053C470.683 651.989 470.615 650.875 470.235 649.85C458.92 631.36 431.829 624.037 429.173 623.358L426.557 624.482L418.892 635.924L419.14 637.231C419.286 637.144 419.439 637.07 419.598 637.009C420.206 636.767 420.854 636.642 421.508 636.643Z" fill="#1D1D1B"/>
<path id="Vector_111" d="M461.955 666.301C464.166 662.474 455.849 653.537 443.38 646.34C430.911 639.144 419.01 636.413 416.8 640.24C414.589 644.068 422.905 653.005 435.375 660.201C447.844 667.398 459.744 670.129 461.955 666.301Z" fill="#9F94FC"/>
<path id="Vector_112" d="M457.453 668.706C455.733 668.672 454.021 668.443 452.352 668.026C446.265 666.442 440.434 664.003 435.033 660.781C423.665 654.243 413.514 644.619 416.235 639.86C418.956 635.1 432.325 639.14 443.705 645.705C455.086 652.269 465.224 661.867 462.516 666.561C461.718 668.012 460.004 668.706 457.453 668.706ZM421.324 639.154C419.296 639.154 417.91 639.637 417.399 640.579C415.62 643.652 422.566 652.047 435.713 659.644C441.013 662.813 446.74 665.208 452.718 666.757C457.296 667.803 460.567 667.516 461.443 665.973C463.222 662.9 456.211 654.505 443.13 646.908C433.921 641.638 425.941 639.154 421.324 639.154Z" fill="#1D1D1B"/>
<path id="Vector_113" d="M457.945 673.243C460.156 669.415 451.84 660.478 439.37 653.282C426.901 646.086 415.001 643.354 412.79 647.182C410.579 651.01 418.895 659.946 431.365 667.143C443.834 674.339 455.734 677.07 457.945 673.243Z" fill="#9F94FC"/>
<path id="Vector_114" d="M453.451 675.636C451.731 675.601 450.02 675.378 448.349 674.969C442.259 673.388 436.427 670.944 431.03 667.712C419.663 661.174 409.512 651.55 412.22 646.79C414.927 642.03 428.322 646.084 439.69 652.648C445.187 655.702 450.218 659.528 454.628 664.011C458.396 668.065 459.743 671.347 458.553 673.504C457.715 674.943 455.989 675.636 453.451 675.636ZM417.321 646.084C415.281 646.084 413.907 646.568 413.397 647.509C411.618 650.582 418.629 658.977 431.71 666.574C437.01 669.744 442.737 672.14 448.716 673.687C453.307 674.747 456.564 674.446 457.454 672.916C458.343 671.373 456.957 668.405 453.765 664.966C449.437 660.583 444.509 656.836 439.127 653.838C429.905 648.568 421.939 646.084 417.321 646.084Z" fill="#1D1D1B"/>
<path id="Vector_115" d="M298.743 573.254C300.953 569.427 296.284 562.595 288.313 557.995C280.342 553.394 272.088 552.768 269.878 556.596C267.667 560.423 272.337 567.255 280.307 571.855C288.278 576.456 296.532 577.082 298.743 573.254Z" fill="#9F94FC"/>
<path id="Vector_116" d="M292.817 576.44C288.267 576.207 283.849 574.831 279.972 572.439C271.56 567.575 266.891 560.488 269.297 556.277C271.704 552.067 280.22 552.577 288.631 557.428C292.264 559.436 295.419 562.206 297.879 565.548C299.999 568.66 300.496 571.511 299.305 573.59C298.193 575.499 295.839 576.44 292.817 576.44ZM275.72 554.773C273.209 554.773 271.273 555.479 270.488 556.944C268.486 560.396 273.104 566.973 280.678 571.328C288.252 575.682 296.218 576.414 298.22 572.962C299.161 571.341 298.664 569.039 296.911 566.32C294.573 563.131 291.563 560.493 288.095 558.592C284.355 556.302 280.1 554.989 275.72 554.773Z" fill="#1D1D1B"/>
<path id="Vector_117" d="M371.275 617.328C373.486 613.5 358.698 600.828 338.245 589.024C317.793 577.22 299.42 570.754 297.21 574.582C294.999 578.409 309.787 591.081 330.24 602.885C350.692 614.689 369.065 621.155 371.275 617.328Z" fill="#9F94FC"/>
<path id="Vector_118" d="M368.242 622.351C364.146 622.125 360.114 621.242 356.299 619.735C336.924 612.261 318.83 601.819 302.667 588.784C294.269 581.592 291.535 576.624 293.811 572.688C296.087 568.752 301.751 568.634 312.19 572.295C331.567 579.765 349.662 590.207 365.822 603.247C374.233 610.451 376.954 615.407 374.691 619.356C373.5 621.331 371.421 622.351 368.242 622.351ZM302.471 577.604C306.016 581.789 315.643 589.922 332.243 599.507C348.843 609.092 360.694 613.354 366.097 614.322C362.552 610.138 352.924 602.004 336.324 592.419C319.724 582.835 307.834 578.585 302.471 577.604Z" fill="#1D1D1B"/>
<path id="Vector_119" d="M367.83 620.291C370.041 616.464 355.253 603.792 334.8 591.988C314.347 580.184 295.975 573.718 293.765 577.545C291.554 581.373 306.342 594.045 326.795 605.849C347.247 617.653 365.619 624.119 367.83 620.291Z" fill="#9F94FC"/>
<path id="Vector_120" d="M364.749 622.024C357.398 622.024 341.073 614.858 326.461 606.424C308.239 595.963 290.384 582.089 293.196 577.225C296.009 572.361 316.86 580.926 335.173 591.386C353.486 601.847 371.25 615.721 368.438 620.599C367.875 621.579 366.567 622.024 364.749 622.024ZM296.728 577.042C295.42 577.042 294.609 577.317 294.295 577.879C292.555 580.886 305.584 592.89 327.076 605.339C348.568 617.787 365.482 623.031 367.221 620.023C368.961 617.003 355.919 604.999 334.44 592.563C316.938 582.429 302.471 577.003 296.767 577.003L296.728 577.042Z" fill="#1D1D1B"/>
<path id="Vector_121" d="M417.295 603.822L426.216 587.137L458.749 557.402L461.731 576.009L432.325 616.545L417.295 603.822Z" fill="#9F94FC"/>
<path id="Vector_122" d="M432.443 617.539L416.471 604.018L425.771 586.692L459.193 556.081L462.411 576.14L432.443 617.539ZM418.119 603.704L432.207 615.629L460.985 575.878L458.291 558.696L426.739 587.464L418.119 603.704Z" fill="#1D1D1B"/>
<path id="Vector_123" d="M465.145 418.102C489.947 418.102 510.052 399.151 510.052 375.774C510.052 352.398 489.947 333.447 465.145 333.447C440.344 333.447 420.238 352.398 420.238 375.774C420.238 399.151 440.344 418.102 465.145 418.102Z" fill="#1D1D1B"/>
<path id="Vector_124" d="M465.147 419.409C439.665 419.409 418.932 399.795 418.932 375.774C418.932 351.754 439.665 332.153 465.147 332.153C490.629 332.153 511.362 351.767 511.362 375.774C511.362 399.782 490.629 419.409 465.147 419.409ZM465.147 334.768C441.13 334.768 421.509 353.166 421.509 375.774C421.509 398.383 441.13 416.794 465.108 416.794C489.085 416.794 508.707 398.396 508.707 375.774C508.707 353.153 489.19 334.768 465.147 334.768Z" fill="#FFB9F9"/>
<path id="Vector_125" d="M357.123 549.549C371.628 548.078 381.942 532.651 380.161 515.092C378.38 497.532 365.177 484.488 350.673 485.959C336.168 487.429 325.854 502.856 327.635 520.416C329.417 537.976 342.619 551.019 357.123 549.549Z" fill="#1D1D1B"/>
<path id="Vector_126" d="M354.951 550.929C340.705 550.929 328.135 537.853 326.382 520.514C325.404 512.064 327.393 503.536 332.007 496.388C333.983 493.124 336.692 490.365 339.92 488.328C343.147 486.291 346.804 485.032 350.602 484.652C354.4 484.271 358.234 484.779 361.802 486.135C365.37 487.491 368.573 489.659 371.158 492.466C377.122 498.536 380.785 506.493 381.518 514.97C383.376 533.276 372.505 549.321 357.305 550.864C356.503 550.907 355.718 550.929 354.951 550.929ZM352.962 487.117C352.259 487.114 351.556 487.148 350.856 487.222C347.459 487.633 344.198 488.801 341.311 490.638C338.425 492.476 335.987 494.936 334.178 497.84C329.89 504.474 328.04 512.392 328.946 520.239C330.646 537.055 343.243 549.595 357.004 548.209C370.766 546.823 380.55 531.995 378.876 515.179C378.198 507.306 374.809 499.912 369.287 494.257C365.008 489.808 359.136 487.239 352.962 487.117Z" fill="#FFB9F9"/>
<path id="Vector_127" d="M310.032 663.527C329.004 663.527 344.383 648.154 344.383 629.19C344.383 610.225 329.004 594.852 310.032 594.852C291.061 594.852 275.682 610.225 275.682 629.19C275.682 648.154 291.061 663.527 310.032 663.527Z" fill="#F6EA28"/>
<path id="Vector_128" d="M310.018 664.181C303.095 664.181 296.327 662.129 290.571 658.284C284.814 654.439 280.328 648.974 277.678 642.58C275.029 636.186 274.336 629.151 275.686 622.363C277.037 615.575 280.371 609.34 285.266 604.447C290.162 599.553 296.399 596.22 303.189 594.87C309.98 593.52 317.018 594.213 323.414 596.861C329.81 599.51 335.277 603.995 339.124 609.749C342.97 615.504 345.023 622.269 345.023 629.189C345.013 638.467 341.321 647.361 334.759 653.921C328.197 660.481 319.299 664.171 310.018 664.181ZM310.018 595.505C303.354 595.505 296.839 597.481 291.298 601.182C285.756 604.883 281.437 610.144 278.887 616.299C276.336 622.454 275.669 629.227 276.969 635.761C278.269 642.295 281.479 648.297 286.191 653.008C290.904 657.719 296.908 660.927 303.445 662.226C309.981 663.526 316.756 662.859 322.914 660.309C329.071 657.76 334.334 653.443 338.036 647.903C341.739 642.364 343.715 635.852 343.715 629.189C343.705 620.259 340.151 611.697 333.834 605.383C327.517 599.068 318.952 595.516 310.018 595.505Z" fill="black"/>
<path id="Vector_129" d="M273.326 596.512C282.733 596.512 290.358 588.89 290.358 579.487C290.358 570.084 282.733 562.462 273.326 562.462C263.92 562.462 256.295 570.084 256.295 579.487C256.295 588.89 263.92 596.512 273.326 596.512Z" fill="#F6EA28"/>
<path id="Vector_130" d="M273.314 597.166C269.813 597.166 266.39 596.128 263.48 594.184C260.569 592.239 258.3 589.475 256.961 586.241C255.622 583.008 255.272 579.45 255.956 576.018C256.64 572.585 258.327 569.433 260.804 566.959C263.28 564.486 266.435 562.802 269.869 562.12C273.303 561.439 276.862 561.791 280.096 563.133C283.33 564.474 286.093 566.743 288.036 569.654C289.979 572.566 291.015 575.987 291.012 579.487C291.005 584.176 289.138 588.671 285.82 591.985C282.502 595.299 278.004 597.163 273.314 597.166ZM273.314 563.168C270.072 563.168 266.903 564.129 264.208 565.929C261.512 567.73 259.412 570.289 258.171 573.283C256.93 576.276 256.606 579.571 257.238 582.749C257.871 585.927 259.432 588.847 261.724 591.138C264.016 593.429 266.937 594.99 270.116 595.622C273.296 596.254 276.591 595.93 279.586 594.69C282.581 593.45 285.141 591.35 286.942 588.655C288.743 585.961 289.704 582.793 289.704 579.553C289.711 577.396 289.292 575.259 288.472 573.264C287.652 571.27 286.445 569.457 284.923 567.929C283.4 566.401 281.591 565.188 279.599 564.36C277.607 563.532 275.471 563.104 273.314 563.103V563.168Z" fill="black"/>
<path id="Vector_131" d="M420.317 686.594C429.723 686.594 437.348 678.971 437.348 669.569C437.348 660.166 429.723 652.544 420.317 652.544C410.91 652.544 403.285 660.166 403.285 669.569C403.285 678.971 410.91 686.594 420.317 686.594Z" fill="#F6EA28"/>
<path id="Vector_132" d="M420.317 687.261C416.816 687.263 413.393 686.228 410.481 684.285C407.569 682.343 405.298 679.581 403.956 676.348C402.615 673.115 402.263 669.558 402.944 666.125C403.625 662.692 405.31 659.539 407.785 657.063C410.259 654.587 413.413 652.901 416.846 652.217C420.28 651.534 423.839 651.883 427.074 653.222C430.309 654.561 433.074 656.828 435.019 659.738C436.964 662.648 438.003 666.069 438.003 669.569C437.999 674.257 436.135 678.753 432.82 682.07C429.504 685.387 425.008 687.254 420.317 687.261ZM420.317 653.197C417.074 653.195 413.904 654.154 411.206 655.953C408.509 657.752 406.406 660.311 405.164 663.306C403.922 666.3 403.596 669.595 404.228 672.775C404.86 675.954 406.421 678.875 408.714 681.167C411.007 683.459 413.929 685.02 417.11 685.652C420.29 686.284 423.587 685.958 426.582 684.716C429.578 683.475 432.138 681.373 433.938 678.676C435.738 675.98 436.697 672.81 436.695 669.569C436.691 665.228 434.965 661.066 431.894 657.996C428.823 654.927 424.66 653.201 420.317 653.197Z" fill="black"/>
<path id="Vector_133" d="M379.322 628.993C388.729 628.993 396.354 621.371 396.354 611.968C396.354 602.566 388.729 594.943 379.322 594.943C369.916 594.943 362.291 602.566 362.291 611.968C362.291 621.371 369.916 628.993 379.322 628.993Z" fill="#F6EA28"/>
<path id="Vector_134" d="M379.321 629.66C375.819 629.663 372.396 628.627 369.483 626.684C366.571 624.741 364.3 621.978 362.959 618.745C361.618 615.511 361.266 611.953 361.949 608.52C362.631 605.087 364.317 601.933 366.793 599.458C369.269 596.983 372.424 595.298 375.858 594.616C379.293 593.933 382.852 594.285 386.087 595.625C389.322 596.966 392.086 599.236 394.029 602.147C395.973 605.059 397.009 608.481 397.007 611.981C397 616.668 395.134 621.161 391.819 624.475C388.504 627.788 384.009 629.653 379.321 629.66ZM379.321 595.662C376.079 595.66 372.909 596.618 370.211 598.417C367.514 600.216 365.411 602.774 364.169 605.768C362.926 608.761 362.6 612.056 363.231 615.235C363.862 618.414 365.422 621.335 367.714 623.627C370.005 625.92 372.926 627.482 376.106 628.115C379.285 628.748 382.582 628.425 385.577 627.185C388.573 625.945 391.134 623.845 392.935 621.151C394.737 618.456 395.698 615.288 395.698 612.047C395.705 609.891 395.287 607.755 394.467 605.762C393.648 603.768 392.443 601.955 390.922 600.427C389.401 598.899 387.593 597.686 385.603 596.858C383.612 596.029 381.477 595.6 379.321 595.597V595.662Z" fill="black"/>
<path id="Vector_135" d="M229.192 548.51C238.598 548.51 246.223 540.887 246.223 531.485C246.223 522.082 238.598 514.46 229.192 514.46C219.785 514.46 212.16 522.082 212.16 531.485C212.16 540.887 219.785 548.51 229.192 548.51Z" fill="#F6EA28"/>
<path id="Vector_136" d="M229.216 549.177C225.716 549.177 222.294 548.139 219.383 546.195C216.473 544.251 214.204 541.488 212.865 538.255C211.525 535.022 211.175 531.465 211.858 528.033C212.541 524.601 214.226 521.449 216.701 518.975C219.177 516.5 222.33 514.815 225.763 514.133C229.197 513.45 232.755 513.8 235.989 515.14C239.223 516.479 241.987 518.746 243.932 521.656C245.877 524.565 246.915 527.986 246.915 531.485C246.911 536.176 245.046 540.674 241.727 543.991C238.409 547.308 233.909 549.173 229.216 549.177ZM229.216 515.113C225.974 515.113 222.804 516.075 220.109 517.876C217.413 519.677 215.312 522.236 214.072 525.231C212.832 528.225 212.508 531.52 213.142 534.699C213.775 537.878 215.337 540.797 217.631 543.088C219.925 545.379 222.846 546.938 226.027 547.569C229.207 548.2 232.503 547.873 235.498 546.631C238.492 545.389 241.051 543.287 242.851 540.591C244.65 537.895 245.609 534.726 245.607 531.485C245.6 527.143 243.87 522.981 240.798 519.912C237.725 516.842 233.56 515.117 229.216 515.113Z" fill="black"/>
</g>
</g>
</g>
</g>
<defs>
<clipPath id="clip0_248_590">
<rect width="768" height="768" fill="white"/>
</clipPath>
</defs>
</svg>

    </div>
<div id="i-rocket-bg" className='svgbg noMobile'>
<svg width="1366" height="768" viewBox="0 0 1366 768" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="rocket-bg" clip-path="url(#clip0_248_242)">
<g id="Group 41">
<g id="Calque 14">
<path id="Vector" d="M477 857L1138.87 -33.5224L1384.11 -35L1376.72 313.531L1248.19 854.045L477 857Z" fill="#2A2E37"/>
</g>
</g>
</g>
<defs>
<clipPath id="clip0_248_242">
<rect width="1366" height="768" fill="white"/>
</clipPath>
</defs>
</svg>

</div>
</>
  );
}

export default Rocket;
