import React, { Component, useEffect } from 'react';
import Footer from "../components/Footer.js"
import { Link } from "react-router-dom";
import Nuages from '../components/Nuages.js'
import ICoins from '../illustrations/Coins.js'

// const a = await fetch('https://metalyde.com/wp/wp-json/wp/v2/pages/27')
// const ABOUT = await a.json()

const Product = (props) => {
  const handleButtonClick = () => {
    props.openModal();
  };

  const scrollTop = () => {
    props.scrollTop();
  };

    return (
      <>

      <section id="product" className="rose">
        <div className="c center">
          <h1 className="smiley">The <br/>power <b>of loyalty</b></h1>
          <p>Our gamified platform elevates loyalty to the next level with entertaining, captivating rewards, and personalized experiences. <br/>Bid farewell to mundane programs and embrace an entirely new level of customer engagement!</p>
        </div>
        <ICoins/>
        <Nuages/>
      </section>

      <section className="rose produits product">
        <div className="c">
          <h2>Create an interactive and engaging loyalty program</h2>
          <ul>
            <li>Accessible only to holders of your digital collectibles.</li>
            <li>Integrate your members into an exclusive world.</li>
            <li>Benefit from a no-code, data-driven, and ready-to-use platform.</li>
          </ul>
        </div>

        <div className="imgCtr">
          <img src="img/f1.png"/>
        </div>
      </section>

      <section className="bleu product">
        <div className="c rtl">
          <h2>Allow your members to collect points and unlock privileged status</h2>
          <ul>
            <li>Create rarity and exclusivity.</li>
            <li>Give your members the benefit of your tips and exclusive content.</li>
            <li>Allow them to unlock new rewards with defined membership status.</li>
          </ul>
        </div>
        <div className="imgCtr rtl">
          <img src="img/f2.png"/>
        </div>
      </section>

      <section className="beige phone product">
        <div className="c rtl">
          <h2>Offer exclusive rewards, based on predefined parameters</h2>
          <ul>
            <li>Make loyalty more exciting, offer a gamified program.</li>
            <li>Reward their loyalty by unlocking physical or digital products that they truly desire.</li>
            <li>Manage your loyalty campaign and make it as attractive as you want.</li>
          </ul>
          <button className="tryit" onClick={handleButtonClick}>try it</button>
        </div>
        <div className="imgCtr rtl">
          <img src="img/svg/diams-yellow.svg" alt="" className='diams'/>
          <img src="img/f3.png"/>
          <div className="phoneContainer">
            <img className="phoneimg" src="img/phone.png"/>
            <span className="fSmile float">
              <img src="img/smiley-2.svg" alt=""/>
            </span>
          </div>
        </div>
      </section>

      <section className="jaune product">
        <div className="c">
          <h2>Interact with your members, communicate and invite them to specific events</h2>
          <ul>
            <li>Create a distinctive bond by making each customer feel like a unique individual among millions.</li>
            <li>Take advantage of a dedicated communication channel to inform them of your latest news.</li>
            <li>Allow them to give their feedback through surveys.</li>
            <li>Invite them to physical or online events directly from the app.</li>
          </ul>
        </div>
        <div className="imgCtr">
          <img src="img/f4.png"/>
        </div>
      </section>

      <section className="rose product">
        <div className="c rtl">
          <h2>Integrate your brand into a multi-brand universe or establish your own unique universe</h2>
          <ul>
            <li>One app, one complete infrastructure, and one engagement system.</li>
            <li>Acquire new members by offering benefits to customers of other brands who may be a good fit for your brand.</li>
            <li>Take advantage of our expertise to keep members coming back through in-app activations and games.</li>
          </ul>
        </div>
        <div className="imgCtr rtl">
          <img src="img/f5.png"/>
        </div>
      </section>

      <section className="bleu product">
        <div className="c">
          <h2>Allow your customers to regain the power of their loyalty</h2>
          <ul>
            <li>Transferable membership card.</li>
            <li>Privacy garanted and reward upon subscription.</li>
            <li>Take advantage of blockchain technology to track and trace collectibles.</li>
          </ul>
          <button className="tryit" onClick={handleButtonClick}>try it</button>
        </div>
        <div className="imgCtr">
          <img src="img/f6.png"/>
        </div>
      </section>

      <section className="beige product">
        <div className="c rtl">
          <h2>Track your program and metrics to make data-driven decisions</h2>
          <ul>
            <li>Track your key KPIs (Retention, churn, CLV, NPS…).</li>
            <li>Segment audiences and offer benefits that really match their expectations.</li>
            <li>Centralize all your data to accurately establish customer profiles, thereby achieving a higher ROI.</li>
          </ul>
        </div>
        <div className="imgCtr rtl">
          <img src="img/f7.png"/>
        </div>
      </section>

      <section className="quote">
        <blockquote><span>“</span>Your brand has the potential <br/>
        to unlock <b>the full power of loyalty!</b><span>”</span></blockquote>

          <a className="button" onClick={handleButtonClick}>Book a Demo</a>

      </section>

      <Footer />
      </>
    );
}

export default Product;
