import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { db } from "../utils/firebase";
import { onValue, ref } from "firebase/database";
import { getDatabase, push } from "firebase/database";

class Footer extends Component {
  state = {
    isValidEmail: false,
    isLoading: false,
    newsletter: []
  }

  componentDidMount() {
    const query = ref(db, "newsletter");
    onValue(query, (snapshot) => {
      const data = snapshot.val();
      const newsletter = data ? Object.values(data) : [];
      this.setState({ newsletter });
    });
  }

  handleEmailChange = (event) => {
    const email = event.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    this.setState({ isValidEmail });
  }

  handleSubscribe = async () => {
    const emailInput = document.querySelector('#subscribe');
    const email = emailInput.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);

    if (isValidEmail) {
      this.setState({ isLoading: true });

      const db = getDatabase();
      const newsletterRef = ref(db, 'newsletter/');
      try {
        const newnewsletterRef = await push(newsletterRef, { email });
        console.log(newnewsletterRef);
        toast.success('Subscription successful!', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      } catch (error) {
        console.log(error);
        toast.error('Failed to add email.', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }

      this.setState({ isLoading: false });
    }
  }

  render() {
    const { isValidEmail, isLoading, newsletter } = this.state;
    return (
      <footer>
        <div>
          <h4>Follow us</h4>
          <ul className="social">
            <li><a href="https://www.linkedin.com/company/unlockapp/" target='_blank' rel='nofollow' title=""><img src="img/icons/linkedin.svg"/></a></li>
              {
                // <li><a href="#" target='_blank' rel='nofollow' title=""><img src="img/icons/instagram.svg"/></a></li>
              }
            <li><a href="https://twitter.com/unlockapp_" target='_blank' rel='nofollow' title=""><img src="img/icons/twitter.svg"/></a></li>
          </ul>
        </div>
        <div>
          <h4>Subscribe and be the first <br/>to enjoy the new advantages</h4>
          <span className="input input--ruri">
            <input className="input__field input__field--ruri" type="text" id="subscribe" onChange={this.handleEmailChange} />
            <label className="input__label input__label--ruri" htmlFor="subscribe">
              <span className="input__label-content input__label-content--ruri">Email</span>
            </label>
          </span>
          <div id="subscribeAction">
            <button id="subscribeButton" onClick={this.handleSubscribe} disabled={!isValidEmail || isLoading} className={isValidEmail ? 'active' : 'disabled'}>
              {isLoading ? 'Loading...' : 'Subscribe'}
            </button>
          </div>
        </div>
        <ToastContainer/>

      </footer>
    );
  }
}

export default Footer;
