import React from 'react';

function ICoins() {
  return (
    <div id="i-coins" >
<svg className="noMobile" width="1366" height="837" viewBox="0 0 1366 837" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="coins" clip-path="url(#clip0_113_2)">
<g id="Group 13">
<path id="Vector" d="M1263.2 797.917C1298.2 798.613 1326.82 786.901 1327.12 771.757C1327.42 756.613 1299.3 743.772 1264.3 743.076C1229.3 742.379 1200.68 754.091 1200.38 769.235C1200.08 784.379 1228.21 797.22 1263.2 797.917Z" fill="#1D1D1B"/>
<path id="Vector_2" d="M1199.65 769.484L1199.65 769.229C1199.96 753.663 1228.96 741.676 1264.3 742.341C1281.28 742.681 1297.28 745.865 1309.24 751.328C1321.2 756.79 1327.96 764.163 1327.82 771.748C1327.52 787.244 1298.51 799.301 1263.18 798.636C1227.84 797.971 1199.65 784.881 1199.65 769.484ZM1326.42 771.522C1326.42 764.588 1320.12 757.894 1308.65 752.658C1296.87 747.28 1281.11 744.167 1264.27 743.799C1229.72 743.105 1201.36 754.526 1201.07 769.272L1201.07 769.498C1201.07 784.131 1228.83 796.542 1263.21 797.235C1297.58 797.929 1326.12 786.494 1326.41 771.762L1326.42 771.522Z" fill="#1D1D1B"/>
<path id="Vector_3" d="M1263.26 787.275C1298.26 787.972 1326.88 776.26 1327.18 761.116C1327.48 745.972 1299.35 733.131 1264.35 732.435C1229.36 731.738 1200.74 743.45 1200.44 758.594C1200.14 773.738 1228.26 786.579 1263.26 787.275Z" fill="#0EB17F"/>
<path id="Vector_4" d="M1199 758.842L1199 758.573C1199.33 742.412 1228.04 730.27 1264.37 731.034C1281.49 731.374 1297.55 734.586 1309.58 740.091C1322.01 745.752 1328.7 753.252 1328.59 761.163C1328.28 777.324 1299.56 789.466 1263.22 788.702C1226.88 787.938 1199 774.89 1199 758.842ZM1325.77 760.88C1325.77 754.243 1319.61 747.775 1308.41 742.667C1296.72 737.317 1281.05 734.176 1264.31 733.864C1230.73 733.199 1202.11 744.535 1201.83 758.63L1201.83 758.842C1201.83 772.852 1229.85 785.206 1263.28 785.872C1296.7 786.537 1325.54 775.201 1325.76 761.106L1325.77 760.88Z" fill="#1D1D1B"/>
<path id="Vector_5" d="M1260.22 748.313L1260.13 745.426L1275.76 745.03L1275.83 747.874L1283.19 747.691L1283.27 750.761L1290.93 750.577L1291 753.563L1283.34 753.762L1283.41 756.719L1276.07 756.903L1276.14 759.946L1283.5 759.762L1283.57 762.762L1291.23 762.578L1291.31 765.564L1283.65 765.762L1283.73 768.776L1276.37 768.96L1276.45 771.918L1260.83 772.314L1260.74 769.3L1253.24 769.484L1253.16 766.54L1245.81 766.724L1245.74 763.767L1238.07 763.965L1238 760.894L1230.64 761.078L1230.57 758.078L1237.93 757.894L1237.85 754.809L1245.5 754.611L1245.43 751.695L1252.79 751.511L1252.71 748.511L1260.22 748.313Z" fill="black"/>
</g>
<g id="Group 18" className="float-2">
<g id="Calque_1" clip-path="url(#clip1_113_2)">
<path id="Vector_6" d="M310.765 544.403C302.401 530.555 269.676 535 237.672 554.331C205.669 573.662 186.505 600.558 194.869 614.406C203.233 628.253 235.958 623.808 267.962 604.477C299.966 585.147 319.129 558.25 310.765 544.403Z" fill="#1D1D1B" stroke="#1D1D1B" stroke-miterlimit="10"/>
<path id="Vector_7" d="M304.914 534.497C296.545 520.651 263.82 525.106 231.82 544.448C199.821 563.789 180.664 590.693 189.033 604.538C197.401 618.384 230.126 613.929 262.126 594.588C294.126 575.247 313.282 548.343 304.914 534.497Z" fill="#9E95F6" stroke="#1D1D1B" stroke-width="2" stroke-miterlimit="10"/>
<path id="Vector_8" d="M244.219 556.995L242.739 554.285L228.066 562.254L229.53 564.939L222.627 568.69L224.197 571.576L217.016 575.482L218.539 578.29L225.72 574.384L227.239 577.158L234.142 573.406L235.689 576.253L228.786 580.005L230.324 582.839L223.143 586.745L224.666 589.553L231.847 585.647L233.385 588.481L240.288 584.729L241.8 587.49L256.461 579.529L254.922 576.696L261.967 572.856L260.468 570.087L267.371 566.335L265.851 563.561L273.045 559.647L271.475 556.761L278.378 553.01L276.855 550.201L269.952 553.953L268.374 551.055L261.18 554.969L259.697 552.225L252.794 555.977L251.272 553.169L244.219 556.995Z" fill="black"/>
</g>
</g>
<g id="Group 12">
<path id="Vector_9" d="M252.617 803.238C287.617 803.934 316.234 792.223 316.535 777.079C316.836 761.935 288.708 749.094 253.708 748.397C218.709 747.701 190.092 759.413 189.79 774.557C189.489 789.7 217.617 802.541 252.617 803.238Z" fill="#1D1D1B"/>
<path id="Vector_10" d="M189.063 774.805L189.063 774.551C189.375 758.984 218.371 746.998 253.708 747.663C270.69 748.002 286.695 751.186 298.653 756.649C310.611 762.111 317.376 769.484 317.234 777.07C316.937 792.566 287.926 804.623 252.59 803.958C217.253 803.293 189.063 790.202 189.063 774.805ZM315.833 776.843C315.833 769.909 309.536 763.215 298.059 757.979C286.285 752.602 270.52 749.488 253.679 749.12C219.135 748.427 190.776 759.847 190.478 774.593L190.478 774.82C190.478 789.452 218.244 801.863 252.618 802.557C286.992 803.25 315.536 791.816 315.819 777.084L315.833 776.843Z" fill="#1D1D1B"/>
<path id="Vector_11" d="M252.676 792.597C287.675 793.293 316.292 781.581 316.594 766.437C316.895 751.294 288.767 738.452 253.767 737.756C218.767 737.059 190.15 748.771 189.849 763.915C189.548 779.059 217.676 791.9 252.676 792.597Z" fill="#0EB17F"/>
<path id="Vector_12" d="M188.412 764.163L188.412 763.895C188.738 747.733 217.452 735.591 253.779 736.356C270.902 736.695 286.964 739.908 298.993 745.413C311.418 751.073 318.112 758.574 317.999 766.484C317.687 782.645 288.974 794.787 252.632 794.023C216.291 793.259 188.412 780.211 188.412 764.163ZM315.182 766.201C315.182 759.564 309.027 753.097 297.818 747.988C286.129 742.639 270.463 739.497 253.722 739.186C220.14 738.521 191.526 749.856 191.243 763.951L191.243 764.163C191.243 778.174 219.263 790.528 252.689 791.193C286.115 791.858 314.956 780.523 315.168 766.428L315.182 766.201Z" fill="#1D1D1B"/>
<path id="Vector_13" d="M249.632 753.635L249.547 750.748L265.17 750.351L265.241 753.196L272.6 753.012L272.685 756.083L280.341 755.899L280.411 758.885L272.755 759.083L272.826 762.041L265.481 762.225L265.552 765.267L272.911 765.083L272.982 768.083L280.638 767.899L280.723 770.885L273.067 771.084L273.137 774.098L265.779 774.282L265.863 777.239L250.24 777.636L250.155 774.621L242.655 774.805L242.57 771.862L235.225 772.046L235.155 769.088L227.484 769.286L227.414 766.215L220.055 766.399L219.984 763.399L227.343 763.215L227.258 760.13L234.914 759.932L234.843 757.017L242.202 756.833L242.117 753.833L249.632 753.635Z" fill="black"/>
</g>
<g id="Group 12_2" className="float-3">
<g id="Calque_1_2" clip-path="url(#clip2_113_2)">
<path id="Vector_14" d="M985.988 772.646C995.069 757.182 973.457 727.633 937.717 706.646C901.977 685.659 865.642 681.182 856.561 696.646C847.481 712.11 869.092 741.66 904.833 762.647C940.573 783.634 976.907 788.111 985.988 772.646Z" fill="#1D1D1B" stroke="#1D1D1B" stroke-miterlimit="10"/>
<path id="Vector_15" d="M992.519 761.876C1001.6 746.411 979.988 716.862 944.248 695.875C908.508 674.888 872.173 670.411 863.093 685.875C854.012 701.339 875.624 730.889 911.364 751.876C947.104 772.863 983.439 777.34 992.519 761.876Z" fill="#F5EB57" stroke="#1D1D1B" stroke-width="2" stroke-miterlimit="10"/>
<path id="Vector_16" d="M938.608 714.476L940.483 711.613L924.959 701.528L923.13 704.362L915.819 699.615L913.833 702.667L906.229 697.722L904.307 700.696L911.91 705.641L909.996 708.578L917.293 713.315L915.338 716.328L908.041 711.591L906.084 714.58L898.481 709.635L896.558 712.609L904.161 717.554L902.217 720.554L909.515 725.291L907.601 728.229L923.124 738.314L925.068 735.314L932.519 740.155L934.422 737.23L941.733 741.978L943.647 739.04L951.251 743.985L953.237 740.934L960.524 745.684L962.47 742.707L955.16 737.96L957.157 734.895L949.554 729.95L951.436 727.051L944.126 722.304L946.061 719.34L938.608 714.476Z" fill="black"/>
</g>
</g>
<g id="Group 12_3" className="float">
<path id="Vector_17" d="M536.637 108.867C536.804 92.6895 506.629 79.263 469.241 78.8779C431.852 78.4929 401.408 91.2953 401.241 107.473C401.075 123.65 431.249 137.077 468.637 137.462C506.026 137.847 536.47 125.045 536.637 108.867Z" fill="#1D1D1B"/>
<path id="Vector_18" d="M470.258 138.198H468.625C450.487 138.017 433.376 134.767 420.543 129.053C407.71 123.34 400.395 115.585 400.485 107.453C400.576 99.3214 407.846 91.7789 420.982 86.292C434.117 80.8051 451.076 77.9634 469.245 78.1448C507.033 78.5378 537.566 92.3381 537.4 108.904C537.233 125.199 507.26 138.198 470.258 138.198ZM467.597 79.611C450.215 79.611 433.935 82.4526 421.571 87.6523C409.207 92.852 402.042 99.9563 402.042 107.469C402.042 114.981 408.768 122.146 421.208 127.678C433.648 133.21 450.713 136.505 468.686 136.747C505.612 137.17 535.767 124.655 535.934 108.904C536.1 93.1543 506.202 80.0645 469.275 79.6715L467.597 79.611Z" fill="#1D1D1B"/>
<path id="Vector_19" d="M536.928 97.4891C537.095 81.3116 506.92 67.8851 469.532 67.5001C432.143 67.115 401.699 79.9173 401.532 96.0948C401.366 112.272 431.54 125.699 468.928 126.084C506.317 126.469 536.761 113.667 536.928 97.4891Z" fill="#FB7243"/>
<path id="Vector_20" d="M470.574 127.602H468.911C430.095 127.209 399.834 113.364 400.001 96.0867C400.167 78.8099 430.685 65.6446 469.531 66.0073C487.806 66.1887 504.992 69.4536 517.9 75.2125C531.217 81.1226 538.502 89.0581 538.412 97.5227C538.321 105.987 530.854 113.757 517.447 119.395C504.825 124.7 488.199 127.602 470.574 127.602ZM467.944 69.0001C432.756 69.0001 403.19 81.2737 402.948 96.1019C402.797 111.217 432.982 124.201 468.866 124.564C486.717 124.761 503.631 121.919 516.222 116.598C528.481 111.444 535.268 104.657 535.343 97.4773C535.419 90.2975 528.768 83.3596 516.615 77.9484C504.07 72.3708 487.322 69.1966 469.44 69.0153H467.929L467.944 69.0001Z" fill="#1D1D1B"/>
<path id="Vector_21" d="M473.417 84.584L473.598 81.4854L456.926 80.5633L456.76 83.6166L448.915 83.1783L448.733 86.4432L440.556 85.9897L440.374 89.1942L448.552 89.6476L448.37 92.7916L456.215 93.23L456.034 96.4798L448.189 96.0414L448.008 99.2458L439.845 98.7924L439.664 101.982L447.841 102.45L447.66 105.655L455.505 106.093L455.324 109.252L471.996 110.174L472.177 106.955L480.188 107.408L480.354 104.249L488.199 104.687L488.381 101.543L496.543 101.997L496.724 98.7168L504.569 99.1552L504.751 95.9507L496.906 95.5275L497.087 92.2324L488.925 91.7789L489.091 88.6651L481.246 88.2268L481.428 85.0223L473.417 84.584Z" fill="black"/>
</g>
<g id="Group 18_2" className="float-2">
<g id="Calque_1_3" clip-path="url(#clip3_113_2)">
<path id="Vector_22" d="M1395.2 219.496C1390.6 203.987 1357.81 200.033 1321.97 210.666C1286.12 221.298 1260.79 242.491 1265.39 258C1270 273.51 1302.78 277.464 1338.63 266.831C1374.47 256.198 1399.8 235.006 1395.2 219.496Z" fill="#1D1D1B" stroke="#1D1D1B" stroke-miterlimit="10"/>
<path id="Vector_23" d="M1392.04 208.435C1387.43 192.926 1354.64 188.982 1318.8 199.626C1282.96 210.27 1257.63 231.471 1262.24 246.98C1266.84 262.489 1299.63 266.433 1335.48 255.789C1371.32 245.146 1396.64 223.944 1392.04 208.435Z" fill="#9E95F6" stroke="#1D1D1B" stroke-width="2" stroke-miterlimit="10"/>
<path id="Vector_24" d="M1327.63 214.896L1326.88 211.9L1310.67 215.91L1311.41 218.877L1303.79 220.766L1304.58 223.954L1296.64 225.922L1297.41 229.023L1305.34 227.055L1306.11 230.123L1313.74 228.234L1314.52 231.379L1306.89 233.268L1307.67 236.398L1299.73 238.366L1300.5 241.468L1308.43 239.5L1309.21 242.63L1316.83 240.741L1317.6 243.794L1333.79 239.789L1333.02 236.659L1340.8 234.721L1340.05 231.663L1347.68 229.773L1346.91 226.705L1354.86 224.733L1354.06 221.545L1361.69 219.655L1360.93 216.554L1353.3 218.443L1352.5 215.241L1344.55 217.213L1343.81 214.184L1336.19 216.073L1335.42 212.972L1327.63 214.896Z" fill="black"/>
</g>
</g>
<g id="Group 15">
<g id="Calque_1_4">
<path id="Vector_25" d="M256.637 807.869C256.803 791.692 226.63 778.266 189.244 777.881C151.857 777.496 121.414 790.298 121.247 806.475C121.08 822.652 151.253 836.078 188.64 836.463C226.027 836.848 256.47 824.046 256.637 807.869Z" fill="#1D1D1B" stroke="#1D1D1B" stroke-miterlimit="10"/>
<path id="Vector_26" d="M256.868 796.367C257.029 780.19 226.851 766.772 189.462 766.398C152.073 766.024 121.632 778.836 121.471 795.013C121.309 811.191 151.487 824.608 188.876 824.982C226.265 825.356 256.706 812.545 256.868 796.367Z" fill="#9E95F6" stroke="#1D1D1B" stroke-width="2" stroke-miterlimit="10"/>
<path id="Vector_27" d="M193.411 783.591L193.577 780.508L176.906 779.571L176.74 782.624L168.895 782.185L168.714 785.465L160.552 785.012L160.371 788.201L168.532 788.654L168.366 791.813L176.211 792.252L176.029 795.486L168.185 795.048L168.003 798.267L159.842 797.814L159.66 801.003L167.822 801.457L167.641 804.676L175.485 805.114L175.319 808.258L191.975 809.195L192.157 805.976L200.167 806.414L200.349 803.27L208.193 803.709L208.36 800.55L216.537 801.003L216.718 797.723L224.562 798.162L224.744 794.972L216.899 794.534L217.081 791.239L208.904 790.786L209.085 787.672L201.241 787.234L201.422 784.044L193.411 783.591Z" fill="black"/>
</g>
</g>
<g id="Group 17" className="float-3">
<g id="Calque_1_5" clip-path="url(#clip4_113_2)">
<path id="Vector_28" d="M95.5165 216.511C101.139 201.342 77.2758 178.511 42.2174 165.518C7.15893 152.524 -25.8192 154.288 -31.4413 169.457C-37.0635 184.627 -13.2006 207.457 21.8578 220.451C56.9163 233.444 89.8944 231.68 95.5165 216.511Z" fill="#1D1D1B" stroke="#1D1D1B" stroke-miterlimit="10"/>
<path id="Vector_29" d="M99.621 205.764C105.239 190.592 81.3681 167.767 46.304 154.784C11.24 141.8 -21.7393 143.573 -27.3572 158.745C-32.9751 173.917 -9.10424 196.741 25.9598 209.725C61.0239 222.709 94.0031 220.935 99.621 205.764Z" fill="#9E95F6" stroke="#1D1D1B" stroke-width="2" stroke-miterlimit="10"/>
<path id="Vector_30" d="M44.2128 172.3L45.411 169.454L30.0364 162.94L28.8484 165.757L21.6133 162.694L20.3345 165.72L12.8057 162.536L11.5575 165.476L19.0863 168.661L17.8625 171.578L25.0977 174.64L23.8342 177.623L16.599 174.561L15.3406 177.529L7.81185 174.345L6.56367 177.286L14.0925 180.47L12.8341 183.439L20.0692 186.502L18.8506 189.404L34.2109 195.914L35.4693 192.945L42.861 196.064L44.0938 193.166L51.329 196.229L52.5527 193.312L60.0957 196.501L61.3746 193.476L68.6097 196.538L69.8579 193.598L62.6228 190.535L63.9067 187.495L56.3637 184.306L57.5863 181.437L50.3512 178.374L51.5993 175.433L44.2128 172.3Z" fill="black"/>
</g>
</g>
<g id="Group 19" className="float">
<path id="Vector_31" d="M963.965 7.03779C951.721 -3.53616 921.984 10.8329 897.546 39.132C873.108 67.4311 863.223 98.9439 875.468 109.518C887.712 120.092 917.45 105.723 941.887 77.4237C966.325 49.1246 976.21 17.6117 963.965 7.03779Z" fill="#1D1D1B"/>
<path id="Vector_32" d="M943.494 76.6622L942.44 77.9087C930.588 91.641 917.058 102.607 904.408 108.716C891.759 114.825 881.114 115.403 874.963 110.083C868.813 104.762 867.749 94.3403 872.041 80.7678C876.334 67.1953 885.116 52.4111 896.987 38.6557C921.689 10.0566 951.943 -4.34498 964.485 6.47986C976.819 17.129 967.389 48.4094 943.494 76.6622ZM897.043 40.8605C885.818 54.1329 877.475 68.3977 873.461 81.1961C869.446 93.9945 870.244 104.053 875.98 108.904C881.716 113.755 891.53 113.246 903.787 107.32C916.045 101.394 929.581 90.4918 941.371 76.9255C965.54 49.0037 975.457 17.8971 963.538 7.59935C951.62 -2.6984 922.318 11.6772 898.172 39.6185L897.043 40.8605Z" fill="#1D1D1B"/>
<path id="Vector_33" d="M955.465 -0.531622C943.221 -11.1056 913.484 3.26348 889.046 31.5626C864.608 59.8616 854.723 91.3744 866.968 101.948C879.212 112.522 908.95 98.1533 933.387 69.8542C957.825 41.5551 967.71 10.0423 955.465 -0.531622Z" fill="#FB7243"/>
<path id="Vector_34" d="M935.61 69.5776L934.536 70.8471C909.17 100.231 879.057 114.396 865.973 103.112C852.889 91.8284 862.544 60.0251 887.906 30.5985C899.845 16.7624 913.436 5.74836 926.169 -0.388923C939.281 -6.74021 950.045 -7.17861 956.449 -1.64329C962.854 3.89202 963.964 14.6104 959.611 28.4882C955.512 41.5512 946.991 56.1206 935.61 69.5776ZM889.166 33.743C866.443 60.6108 856.722 91.1111 867.888 100.871C879.331 110.747 908.738 96.0843 932.187 68.9198C943.865 55.4166 952.617 40.6669 956.685 27.6173C960.666 14.929 959.867 5.36436 954.433 0.670253C949 -4.02385 939.408 -3.42593 927.428 2.35879C915.068 8.33622 901.83 19.0741 890.144 32.6102L889.168 33.7643L889.166 33.743Z" fill="#1D1D1B"/>
<path id="Vector_35" d="M904.598 39.6285L902.35 37.4891L890.879 49.6236L893.103 51.7222L887.703 57.429L890.078 59.6759L884.452 65.6268L886.781 67.8346L892.408 61.8836L894.691 64.0524L900.092 58.3456L902.456 60.5827L897.056 66.2895L899.385 68.4973L893.768 74.4367L896.086 76.6347L901.725 70.6935L904.054 72.9013L909.455 67.1945L911.75 69.373L923.22 57.2385L920.879 55.021L926.398 49.1969L924.094 47.03L929.494 41.3232L927.211 39.1545L932.828 33.215L930.441 30.9584L935.841 25.2516L933.512 23.0438L928.122 28.7604L925.724 26.494L920.107 32.4334L917.836 30.2958L912.436 36.0026L910.106 33.7948L904.598 39.6285Z" fill="black"/>
</g>
<g id="Group 14" className="float-2">
<path id="Vector_36" d="M1006.6 57.5349C1032.63 34.1306 1045.53 6.02647 1035.4 -5.23747C1025.27 -16.5014 995.962 -6.6597 969.929 16.7446C943.897 40.1489 931.002 68.2531 941.129 79.517C951.256 90.781 980.569 80.9392 1006.6 57.5349Z" fill="#1D1D1B"/>
<path id="Vector_37" d="M940.77 80.1947L940.595 80.0087C930.187 68.4291 943.171 39.8652 969.428 16.2078C982.06 4.85325 995.923 -3.75716 1008.39 -7.93858C1020.85 -12.12 1030.83 -11.3579 1035.91 -5.72248C1046.28 5.79575 1033.33 34.421 1007.07 58.0784C980.817 81.7357 951.289 91.4375 940.77 80.1947ZM1034.73 -4.9306C1029.99 -9.99402 1020.82 -10.5791 1008.86 -6.56113C996.59 -2.4434 982.952 6.05429 970.403 17.2915C944.706 40.3868 931.8 68.1022 941.658 79.0728L941.813 79.2381C951.81 89.923 980.564 80.0152 1006.14 57.036C1031.71 34.0567 1044.74 6.20528 1034.88 -4.74527L1034.73 -4.9306Z" fill="#1D1D1B"/>
<path id="Vector_38" d="M999.375 49.7237C1025.41 26.3194 1038.3 -1.78477 1028.17 -13.0487C1018.05 -24.3127 988.735 -14.4709 962.703 8.93335C936.67 32.3377 923.776 60.4418 933.902 71.7058C944.029 82.9697 973.342 73.128 999.375 49.7237Z" fill="#0EB17F"/>
<path id="Vector_39" d="M933.023 72.8687L932.839 72.6723C922.035 60.6491 934.706 32.1649 961.754 7.90304C974.489 -3.54824 988.413 -12.1767 1000.96 -16.3754C1013.9 -20.7312 1023.91 -19.8278 1029.23 -13.9741C1040.05 -1.96048 1027.38 26.5237 1000.32 50.7952C973.259 75.0668 943.987 84.5869 933.023 72.8687ZM1026.98 -12.2566C1022.45 -17.1031 1013.53 -17.6195 1001.86 -13.6922C989.669 -9.61184 976.084 -1.20249 963.646 10.0084C938.67 32.4668 925.521 60.2943 934.944 70.7799L935.089 70.9349C944.661 81.1651 973.563 71.0419 998.425 48.6898C1023.29 26.3378 1036.6 -1.64442 1027.13 -12.0816L1026.98 -12.2566Z" fill="#1D1D1B"/>
<path id="Vector_40" d="M970.533 23.3532L968.498 21.3032L979.636 10.3395L981.631 12.3682L986.879 7.20605L989.039 9.3904L994.503 4.02523L996.595 6.15725L991.14 11.5328L993.213 13.6441L987.975 18.7966L990.106 20.9699L995.353 15.8078L997.455 17.9502L1002.92 12.585L1005.02 14.7074L999.567 20.0828L1001.68 22.2355L996.43 27.3977L998.513 29.4994L987.375 40.4631L985.254 38.32L979.903 43.5789L977.83 41.4875L972.592 46.64L970.52 44.5286L965.054 49.9138L962.905 47.7198L957.657 52.8819L955.555 50.7395L960.803 45.5774L958.633 43.3827L964.088 38.0072L962.045 35.9268L967.293 30.7647L965.181 28.632L970.533 23.3532Z" fill="black"/>
</g>
</g>
<defs>
<clipPath id="clip0_113_2">
<rect width="1366" height="837" fill="white"/>
</clipPath>
<clipPath id="clip1_113_2">
<rect width="138.42" height="72.2178" fill="white" transform="translate(172 579.782) rotate(-31.7227)"/>
</clipPath>
<clipPath id="clip2_113_2">
<rect width="83.2891" height="152.923" fill="white" transform="translate(839 712.359) rotate(-50.5982)"/>
</clipPath>
<clipPath id="clip3_113_2">
<rect width="138.42" height="72.2178" fill="white" transform="translate(1252 218.728) rotate(-17.1117)"/>
</clipPath>
<clipPath id="clip4_113_2">
<rect width="138.42" height="72.2178" fill="white" transform="translate(-18.6016 130) rotate(19.746)"/>
</clipPath>
</defs>
</svg>















<svg className='mobile' width="1366" height="837" viewBox="0 0 1366 837" fill="none">
<g id="coins 3" clip-path="url(#clip0_112_2)">
<g id="Clip path group">
<mask id="mask0_112_2" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="1366" height="837">
<g id="clip0_113_2">
<path id="Vector" d="M1366 0H0V837H1366V0Z" fill="white"/>
</g>
</mask>
<g mask="url(#mask0_112_2)">
<g id="coins">
<g id="Group 12_3"  className="float">
<path id="Vector_17" d="M536.638 324.867C536.805 308.689 506.63 295.263 469.242 294.878C431.853 294.493 401.409 307.295 401.242 323.473C401.076 339.65 431.25 353.077 468.638 353.462C506.027 353.847 536.471 341.045 536.638 324.867Z" fill="#1D1D1B"/>
<path id="Vector_18" d="M470.258 354.198H468.625C450.487 354.017 433.376 350.767 420.543 345.053C407.71 339.34 400.395 331.585 400.485 323.453C400.576 315.321 407.846 307.779 420.982 302.292C434.117 296.805 451.076 293.963 469.245 294.145C507.033 294.538 537.566 308.338 537.4 324.904C537.233 341.199 507.26 354.198 470.258 354.198ZM467.597 295.611C450.215 295.611 433.935 298.453 421.571 303.652C409.207 308.852 402.042 315.956 402.042 323.469C402.042 330.981 408.768 338.146 421.208 343.678C433.648 349.21 450.713 352.505 468.686 352.747C505.612 353.17 535.767 340.655 535.934 324.904C536.1 309.154 506.202 296.065 469.275 295.672L467.597 295.611Z" fill="#1D1D1B"/>
<path id="Vector_19" d="M536.929 313.489C537.096 297.311 506.921 283.885 469.533 283.5C432.144 283.115 401.7 295.917 401.533 312.094C401.367 328.272 431.541 341.699 468.929 342.084C506.318 342.469 536.762 329.667 536.929 313.489Z" fill="#FB7243"/>
<path id="Vector_20" d="M470.575 343.602H468.912C430.096 343.209 399.835 329.364 400.002 312.087C400.168 294.81 430.686 281.645 469.532 282.007C487.807 282.189 504.993 285.454 517.901 291.213C531.218 297.123 538.503 305.058 538.413 313.523C538.322 321.987 530.855 329.757 517.448 335.395C504.826 340.7 488.2 343.602 470.575 343.602ZM467.945 285C432.757 285 403.191 297.274 402.949 312.102C402.798 327.217 432.983 340.201 468.867 340.564C486.718 340.761 503.632 337.919 516.223 332.598C528.482 327.444 535.269 320.657 535.344 313.477C535.42 306.298 528.769 299.36 516.616 293.948C504.071 288.371 487.323 285.197 469.441 285.015H467.93L467.945 285Z" fill="#1D1D1B"/>
<path id="Vector_21" d="M473.418 300.584L473.599 297.486L456.927 296.563L456.761 299.617L448.916 299.178L448.734 302.443L440.557 301.99L440.375 305.194L448.553 305.648L448.371 308.792L456.216 309.23L456.035 312.48L448.19 312.042L448.009 315.246L439.846 314.793L439.665 317.982L447.842 318.45L447.661 321.655L455.506 322.093L455.325 325.252L471.997 326.174L472.178 322.955L480.189 323.408L480.355 320.249L488.2 320.687L488.382 317.543L496.544 317.997L496.725 314.717L504.57 315.155L504.752 311.951L496.907 311.528L497.088 308.233L488.926 307.779L489.092 304.665L481.247 304.227L481.429 301.022L473.418 300.584Z" fill="black"/>
</g>
<g id="Group 18_2"  className="float-2">
<g id="Clip path group_2">
<mask id="mask1_112_2" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="1252" y="394" width="154" height="110">
<g id="clip3_113_2">
<path id="Vector_2" d="M1384.29 394L1252 434.729L1273.25 503.749L1405.54 463.021L1384.29 394Z" fill="white"/>
</g>
</mask>
<g mask="url(#mask1_112_2)">
<g id="Calque_1_3">
<path id="Vector_22" d="M1395.2 435.496C1390.6 419.987 1357.81 416.033 1321.97 426.666C1286.12 437.298 1260.79 458.491 1265.39 474C1270 489.51 1302.78 493.464 1338.63 482.831C1374.47 472.198 1399.8 451.006 1395.2 435.496Z" fill="#1D1D1B" stroke="#1D1D1B" stroke-miterlimit="10"/>
<path id="Vector_23" d="M1392.04 424.435C1387.43 408.926 1354.64 404.982 1318.8 415.626C1282.96 426.27 1257.63 447.471 1262.24 462.98C1266.84 478.489 1299.63 482.433 1335.48 471.789C1371.32 461.146 1396.64 439.944 1392.04 424.435Z" fill="#9E95F6" stroke="#1D1D1B" stroke-width="2" stroke-miterlimit="10"/>
<path id="Vector_24" d="M1327.63 430.896L1326.88 427.9L1310.67 431.91L1311.41 434.877L1303.79 436.766L1304.58 439.954L1296.64 441.922L1297.41 445.023L1305.34 443.055L1306.11 446.123L1313.74 444.234L1314.52 447.379L1306.89 449.268L1307.67 452.398L1299.73 454.366L1300.5 457.468L1308.43 455.5L1309.21 458.63L1316.83 456.741L1317.6 459.794L1333.79 455.789L1333.02 452.659L1340.8 450.721L1340.05 447.663L1347.68 445.773L1346.91 442.705L1354.86 440.733L1354.06 437.545L1361.69 435.655L1360.93 432.554L1353.3 434.443L1352.5 431.241L1344.55 433.213L1343.81 430.184L1336.19 432.073L1335.42 428.972L1327.63 430.896Z" fill="black"/>
</g>
</g>
</g>
</g>
<g id="Group 17"  className="float-3">
<g id="Clip path group_3">
<mask id="mask2_112_2" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="-43" y="346" width="155" height="115">
<g id="clip4_113_2">
<path id="Vector_3" d="M111.679 392.765L-18.6016 346L-43.0004 413.971L87.2804 460.737L111.679 392.765Z" fill="white"/>
</g>
</mask>
<g mask="url(#mask2_112_2)">
<g id="Calque_1_5">
<path id="Vector_28" d="M95.5167 432.511C101.139 417.342 77.276 394.511 42.2176 381.518C7.15913 368.524 -25.819 370.288 -31.4411 385.457C-37.0633 400.627 -13.2004 423.457 21.858 436.451C56.9165 449.444 89.8946 447.68 95.5167 432.511Z" fill="#1D1D1B" stroke="#1D1D1B" stroke-miterlimit="10"/>
<path id="Vector_29" d="M99.6217 421.764C105.24 406.592 81.3688 383.767 46.3047 370.784C11.2407 357.8 -21.7386 359.573 -27.3565 374.745C-32.9744 389.917 -9.10357 412.741 25.9605 425.725C61.0246 438.709 94.0038 436.935 99.6217 421.764Z" fill="#9E95F6" stroke="#1D1D1B" stroke-width="2" stroke-miterlimit="10"/>
<path id="Vector_30" d="M44.2126 388.3L45.4108 385.454L30.0362 378.94L28.8482 381.757L21.6131 378.694L20.3343 381.72L12.8055 378.536L11.5573 381.476L19.0861 384.661L17.8623 387.578L25.0975 390.64L23.834 393.623L16.5988 390.561L15.3404 393.529L7.81166 390.345L6.56348 393.286L14.0923 396.47L12.8339 399.439L20.069 402.502L18.8504 405.404L34.2107 411.914L35.4691 408.945L42.8608 412.064L44.0936 409.166L51.3288 412.229L52.5525 409.312L60.0955 412.501L61.3744 409.476L68.6095 412.538L69.8577 409.598L62.6226 406.535L63.9065 403.495L56.3635 400.306L57.5861 397.437L50.351 394.374L51.5991 391.433L44.2126 388.3Z" fill="black"/>
</g>
</g>
</g>
</g>
<g id="Group 19" className="float">
<path id="Vector_31" d="M963.966 7.03743C951.722 -3.53652 921.984 10.8325 897.546 39.1316C873.108 67.4307 863.223 98.9435 875.468 109.518C887.712 120.092 917.451 105.723 941.888 77.4233C966.326 49.1242 976.211 17.6113 963.966 7.03743Z" fill="#1D1D1B"/>
<path id="Vector_32" d="M943.494 76.6624L942.44 77.9089C930.588 91.6412 917.059 102.607 904.409 108.716C891.76 114.825 881.114 115.403 874.963 110.083C868.813 104.762 867.75 94.3405 872.042 80.768C876.335 67.1955 885.116 52.4113 896.987 38.6559C921.689 10.0568 951.943 -4.34477 964.485 6.48007C976.819 17.1292 967.389 48.4096 943.494 76.6624ZM897.044 40.8607C885.819 54.1331 877.475 68.3979 873.461 81.1963C869.446 93.9947 870.244 104.053 875.98 108.904C881.716 113.755 891.53 113.246 903.787 107.32C916.045 101.394 929.581 90.492 941.371 76.9257C965.54 49.0039 975.458 17.8973 963.539 7.59956C951.621 -2.69819 922.318 11.6774 898.172 39.6187L897.044 40.8607Z" fill="#1D1D1B"/>
<path id="Vector_33" d="M955.466 -0.531894C943.222 -11.1059 913.484 3.26321 889.046 31.5623C864.608 59.8613 854.723 91.3741 866.968 101.948C879.212 112.522 908.951 98.153 933.388 69.8539C957.826 41.5548 967.711 10.042 955.466 -0.531894Z" fill="#FB7243"/>
<path id="Vector_34" d="M935.611 69.5777L934.537 70.8472C909.171 100.231 879.058 114.396 865.974 103.112C852.89 91.8285 862.545 60.0252 887.907 30.5986C899.846 16.7625 913.437 5.74845 926.17 -0.388835C939.282 -6.74012 950.046 -7.17852 956.45 -1.6432C962.855 3.89211 963.965 14.6105 959.612 28.4883C955.513 41.5513 946.992 56.1207 935.611 69.5777ZM889.167 33.7431C866.444 60.6109 856.723 91.1112 867.889 100.871C879.332 110.747 908.739 96.0844 932.188 68.9199C943.866 55.4167 952.618 40.667 956.686 27.6174C960.667 14.9291 959.868 5.36445 954.434 0.670341C949.001 -4.02376 939.409 -3.42584 927.429 2.35888C915.069 8.33631 901.831 19.0742 890.145 32.6103L889.169 33.7644L889.167 33.7431Z" fill="#1D1D1B"/>
<path id="Vector_35" d="M904.598 39.6286L902.35 37.4892L890.879 49.6237L893.103 51.7223L887.703 57.4291L890.078 59.676L884.452 65.6269L886.781 67.8347L892.408 61.8837L894.691 64.0525L900.092 58.3457L902.456 60.5828L897.056 66.2896L899.385 68.4974L893.768 74.4368L896.086 76.6348L901.725 70.6936L904.054 72.9014L909.455 67.1946L911.75 69.3731L923.22 57.2386L920.879 55.0211L926.398 49.197L924.094 47.0301L929.494 41.3233L927.211 39.1546L932.828 33.2151L930.441 30.9585L935.841 25.2517L933.512 23.0439L928.122 28.7605L925.724 26.4941L920.107 32.4335L917.836 30.2959L912.436 36.0027L910.106 33.7949L904.598 39.6286Z" fill="black"/>
</g>
<g id="Group 14" className="float-2">
<path id="Vector_36" d="M1006.6 57.5353C1032.63 34.131 1045.53 6.02684 1035.4 -5.2371C1025.27 -16.501 995.962 -6.65933 969.929 16.745C943.897 40.1493 931.002 68.2535 941.129 79.5174C951.256 90.7814 980.569 80.9396 1006.6 57.5353Z" fill="#1D1D1B"/>
<path id="Vector_37" d="M940.771 80.1945L940.595 80.0085C930.187 68.4289 943.171 39.865 969.428 16.2076C982.06 4.85309 995.924 -3.75732 1008.39 -7.93874C1020.85 -12.1202 1030.83 -11.3581 1035.91 -5.72264C1046.28 5.79559 1033.33 34.4208 1007.07 58.0782C980.818 81.7355 951.29 91.4373 940.771 80.1945ZM1034.73 -4.93076C1029.99 -9.99418 1020.82 -10.5793 1008.86 -6.56129C996.59 -2.44356 982.953 6.05413 970.404 17.2913C944.707 40.3866 931.801 68.102 941.659 79.0726L941.813 79.2379C951.81 89.9228 980.565 80.015 1006.14 57.0358C1031.71 34.0565 1044.74 6.20512 1034.88 -4.74543L1034.73 -4.93076Z" fill="#1D1D1B"/>
<path id="Vector_38" d="M999.375 49.7238C1025.41 26.3195 1038.3 -1.78466 1028.17 -13.0486C1018.05 -24.3126 988.735 -14.4708 962.703 8.93345C936.67 32.3378 923.776 60.4419 933.902 71.7059C944.029 82.9698 973.342 73.1281 999.375 49.7238Z" fill="#0EB17F"/>
<path id="Vector_39" d="M933.024 72.8686L932.84 72.6722C922.036 60.649 934.707 32.1648 961.755 7.90296C974.49 -3.54832 988.414 -12.1768 1000.96 -16.3755C1013.9 -20.7313 1023.91 -19.8279 1029.23 -13.9742C1040.05 -1.96056 1027.38 26.5236 1000.32 50.7951C973.26 75.0667 943.988 84.5868 933.024 72.8686ZM1026.98 -12.2567C1022.45 -17.1032 1013.53 -17.6196 1001.86 -13.6923C989.669 -9.61192 976.085 -1.20257 963.647 10.0083C938.671 32.4667 925.521 60.2942 934.944 70.7798L935.09 70.9348C944.662 81.165 973.563 71.0418 998.425 48.6897C1023.29 26.3377 1036.6 -1.6445 1027.13 -12.0817L1026.98 -12.2567Z" fill="#1D1D1B"/>
<path id="Vector_40" d="M970.534 23.3534L968.499 21.3034L979.637 10.3397L981.632 12.3684L986.88 7.20621L989.04 9.39056L994.504 4.02539L996.596 6.15741L991.141 11.533L993.214 13.6443L987.976 18.7968L990.107 20.9701L995.354 15.808L997.456 17.9504L1002.92 12.5852L1005.02 14.7076L999.568 20.083L1001.68 22.2357L996.431 27.3979L998.514 29.4996L987.376 40.4633L985.255 38.3202L979.904 43.5791L977.831 41.4877L972.593 46.6402L970.521 44.5288L965.055 49.914L962.906 47.72L957.658 52.8821L955.556 50.7397L960.804 45.5776L958.634 43.3829L964.089 38.0074L962.046 35.927L967.294 30.7649L965.182 28.6322L970.534 23.3534Z" fill="black"/>
</g>
</g>
</g>
</g>
</g>
<defs>
<clipPath id="clip0_112_2">
<rect width="1366" height="837" fill="white"/>
</clipPath>
</defs>
</svg>







<svg className='mobile bottom' width="1366" height="837" viewBox="0 0 1366 837" fill="none">
<g clip-path="url(#clip0_60_588)">
<mask id="mask0_60_588" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="1366" height="837">
<path d="M1366 0H0V837H1366V0Z" fill="white"/>
</mask>
<g mask="url(#mask0_60_588)">
<path d="M1263.2 797.916C1298.2 798.612 1326.82 786.9 1327.12 771.756C1327.42 756.612 1299.3 743.771 1264.3 743.075C1229.3 742.378 1200.68 754.09 1200.38 769.234C1200.08 784.378 1228.21 797.219 1263.2 797.916Z" fill="#1D1D1B"/>
<path d="M1199.65 769.483V769.228C1199.96 753.662 1228.96 741.675 1264.3 742.34C1281.28 742.68 1297.28 745.864 1309.24 751.327C1321.2 756.789 1327.96 764.162 1327.82 771.747C1327.52 787.243 1298.51 799.3 1263.18 798.635C1227.84 797.97 1199.65 784.88 1199.65 769.483ZM1326.42 771.521C1326.42 764.587 1320.12 757.893 1308.65 752.657C1296.87 747.279 1281.11 744.166 1264.27 743.798C1229.72 743.104 1201.36 754.525 1201.07 769.271V769.497C1201.07 784.13 1228.83 796.541 1263.21 797.234C1297.58 797.928 1326.12 786.493 1326.41 771.761L1326.42 771.521Z" fill="#1D1D1B"/>
<path d="M1263.26 787.274C1298.26 787.971 1326.88 776.259 1327.18 761.115C1327.48 745.971 1299.35 733.13 1264.35 732.434C1229.36 731.737 1200.74 743.449 1200.44 758.593C1200.14 773.737 1228.26 786.578 1263.26 787.274Z" fill="#0EB17F"/>
<path d="M1199 758.841V758.572C1199.33 742.411 1228.04 730.269 1264.37 731.033C1281.49 731.373 1297.55 734.585 1309.58 740.09C1322.01 745.751 1328.7 753.251 1328.59 761.162C1328.28 777.323 1299.56 789.465 1263.22 788.701C1226.88 787.937 1199 774.889 1199 758.841ZM1325.77 760.879C1325.77 754.242 1319.61 747.774 1308.41 742.666C1296.72 737.316 1281.05 734.175 1264.31 733.863C1230.73 733.198 1202.11 744.534 1201.83 758.629V758.841C1201.83 772.851 1229.85 785.205 1263.28 785.871C1296.7 786.536 1325.54 775.2 1325.76 761.105L1325.77 760.879Z" fill="#1D1D1B"/>
<path d="M1260.22 748.312L1260.13 745.425L1275.76 745.029L1275.83 747.873L1283.19 747.69L1283.27 750.76L1290.93 750.576L1291 753.562L1283.34 753.761L1283.41 756.718L1276.07 756.902L1276.14 759.945L1283.5 759.761L1283.57 762.761L1291.23 762.577L1291.31 765.563L1283.65 765.761L1283.73 768.775L1276.37 768.959L1276.45 771.917L1260.83 772.313L1260.74 769.299L1253.24 769.483L1253.16 766.539L1245.81 766.723L1245.74 763.766L1238.07 763.964L1238 760.893L1230.64 761.077L1230.57 758.077L1237.93 757.893L1237.85 754.808L1245.5 754.61L1245.43 751.694L1252.79 751.51L1252.71 748.51L1260.22 748.312Z" fill="black"/>
<mask id="mask1_60_588" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="172" y="506" width="156" height="136">
<path d="M289.741 506.999L172.001 579.781L209.974 641.21L327.714 568.427L289.741 506.999Z" fill="white"/>
</mask>
<g mask="url(#mask1_60_588)" class="float-3">
<path d="M310.766 544.402C302.402 530.554 269.677 534.999 237.673 554.33C205.67 573.661 186.506 600.557 194.87 614.405C203.234 628.252 235.959 623.807 267.963 604.476C299.967 585.146 319.13 558.249 310.766 544.402Z" fill="#1D1D1B" stroke="#1D1D1B" stroke-miterlimit="10"/>
<path d="M304.915 534.496C296.546 520.65 263.821 525.105 231.821 544.447C199.822 563.788 180.665 590.692 189.034 604.537C197.402 618.383 230.127 613.928 262.127 594.587C294.127 575.246 313.283 548.342 304.915 534.496Z" fill="#9E95F6" stroke="#1D1D1B" stroke-width="2" stroke-miterlimit="10"/>
<path d="M244.22 556.994L242.74 554.284L228.067 562.253L229.531 564.938L222.628 568.689L224.198 571.575L217.017 575.481L218.54 578.289L225.721 574.383L227.24 577.157L234.143 573.405L235.69 576.252L228.787 580.004L230.325 582.838L223.144 586.744L224.667 589.552L231.848 585.646L233.386 588.48L240.289 584.728L241.801 587.489L256.462 579.528L254.923 576.695L261.968 572.855L260.469 570.086L267.372 566.334L265.852 563.56L273.046 559.646L271.476 556.76L278.379 553.009L276.856 550.2L269.953 553.952L268.375 551.054L261.181 554.968L259.698 552.224L252.795 555.976L251.273 553.168L244.22 556.994Z" fill="black"/>
</g>
<path d="M252.618 803.238C287.618 803.934 316.235 792.223 316.536 777.079C316.837 761.935 288.709 749.094 253.709 748.397C218.71 747.701 190.093 759.413 189.791 774.557C189.49 789.7 217.618 802.541 252.618 803.238Z" fill="#1D1D1B"/>
<path d="M189.064 774.804V774.55C189.376 758.983 218.372 746.997 253.709 747.662C270.691 748.001 286.696 751.185 298.654 756.648C310.612 762.11 317.377 769.483 317.235 777.069C316.938 792.565 287.927 804.622 252.591 803.957C217.254 803.292 189.064 790.201 189.064 774.804ZM315.834 776.842C315.834 769.908 309.537 763.214 298.06 757.978C286.286 752.601 270.521 749.487 253.68 749.119C219.136 748.426 190.777 759.846 190.479 774.592V774.819C190.479 789.451 218.245 801.862 252.619 802.556C286.993 803.249 315.537 791.815 315.82 777.083L315.834 776.842Z" fill="#1D1D1B"/>
<path d="M252.677 792.596C287.676 793.292 316.293 781.58 316.595 766.436C316.896 751.293 288.768 738.451 253.768 737.755C218.768 737.058 190.151 748.77 189.85 763.914C189.549 779.058 217.677 791.899 252.677 792.596Z" fill="#0EB17F"/>
<path d="M188.413 764.163V763.895C188.739 747.733 217.453 735.591 253.78 736.356C270.903 736.695 286.965 739.908 298.994 745.413C311.419 751.073 318.113 758.574 318 766.484C317.688 782.645 288.975 794.787 252.633 794.023C216.292 793.259 188.413 780.211 188.413 764.163ZM315.183 766.201C315.183 759.563 309.028 753.096 297.819 747.987C286.13 742.638 270.464 739.496 253.723 739.185C220.141 738.52 191.527 749.856 191.244 763.951V764.163C191.244 778.174 219.264 790.528 252.69 791.193C286.116 791.858 314.957 780.522 315.169 766.427L315.183 766.201Z" fill="#1D1D1B"/>
<path d="M249.633 753.635L249.548 750.748L265.171 750.351L265.242 753.196L272.601 753.012L272.686 756.083L280.342 755.899L280.412 758.885L272.756 759.083L272.827 762.041L265.482 762.225L265.553 765.267L272.912 765.083L272.983 768.083L280.639 767.899L280.724 770.885L273.068 771.084L273.138 774.098L265.78 774.282L265.864 777.239L250.241 777.636L250.156 774.621L242.656 774.805L242.571 771.862L235.226 772.046L235.156 769.088L227.485 769.286L227.415 766.215L220.056 766.399L219.985 763.399L227.344 763.215L227.259 760.13L234.915 759.932L234.844 757.017L242.203 756.833L242.118 753.833L249.633 753.635Z" fill="black"/>
<mask id="mask2_60_588" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="839" y="648" width="172" height="162">
<path d="M891.869 648L839.001 712.358L957.167 809.427L1010.03 745.068L891.869 648Z" fill="white"/>
</mask>
<g mask="url(#mask2_60_588)" class="float-2">
<path d="M985.989 772.646C995.07 757.182 973.458 727.633 937.718 706.646C901.978 685.659 865.643 681.182 856.562 696.646C847.482 712.11 869.093 741.66 904.834 762.647C940.574 783.634 976.908 788.111 985.989 772.646Z" fill="#1D1D1B" stroke="#1D1D1B" stroke-miterlimit="10"/>
<path d="M992.52 761.876C1001.6 746.411 979.989 716.862 944.249 695.875C908.509 674.888 872.174 670.411 863.094 685.875C854.013 701.339 875.625 730.889 911.365 751.876C947.105 772.863 983.44 777.34 992.52 761.876Z" fill="#F5EB57" stroke="#1D1D1B" stroke-width="2" stroke-miterlimit="10"/>
<path d="M938.609 714.476L940.484 711.613L924.96 701.528L923.131 704.362L915.82 699.615L913.834 702.667L906.23 697.722L904.308 700.696L911.911 705.641L909.997 708.578L917.294 713.315L915.339 716.328L908.042 711.591L906.085 714.58L898.482 709.635L896.559 712.609L904.162 717.554L902.218 720.554L909.516 725.291L907.602 728.229L923.125 738.314L925.069 735.314L932.52 740.155L934.423 737.23L941.734 741.978L943.648 739.04L951.252 743.985L953.238 740.934L960.525 745.684L962.471 742.707L955.161 737.96L957.158 734.895L949.555 729.95L951.437 727.051L944.127 722.304L946.062 719.34L938.609 714.476Z" fill="black"/>
</g>
<path d="M256.638 807.869C256.804 791.692 226.631 778.266 189.245 777.881C151.858 777.496 121.415 790.298 121.248 806.475C121.081 822.652 151.254 836.078 188.641 836.463C226.028 836.848 256.471 824.046 256.638 807.869Z" fill="#1D1D1B" stroke="#1D1D1B" stroke-miterlimit="10"/>
<path d="M256.868 796.367C257.029 780.19 226.851 766.772 189.462 766.398C152.073 766.024 121.632 778.836 121.471 795.013C121.309 811.191 151.487 824.608 188.876 824.982C226.265 825.356 256.706 812.545 256.868 796.367Z" fill="#9E95F6" stroke="#1D1D1B" stroke-width="2" stroke-miterlimit="10"/>
<path d="M193.412 783.59L193.578 780.507L176.907 779.57L176.741 782.623L168.896 782.184L168.715 785.464L160.553 785.011L160.372 788.2L168.533 788.653L168.367 791.812L176.212 792.251L176.03 795.485L168.186 795.047L168.004 798.266L159.843 797.813L159.661 801.002L167.823 801.456L167.642 804.675L175.486 805.113L175.32 808.257L191.976 809.194L192.158 805.975L200.168 806.413L200.35 803.269L208.194 803.708L208.361 800.549L216.538 801.002L216.719 797.722L224.563 798.161L224.745 794.971L216.9 794.533L217.082 791.238L208.905 790.785L209.086 787.671L201.242 787.233L201.423 784.043L193.412 783.59Z" fill="black"/>
</g>
</g>
<defs>
<clipPath id="clip0_60_588">
<rect width="1366" height="837" fill="white"/>
</clipPath>
</defs>
</svg>


    </div>
  );
}

export default ICoins;
