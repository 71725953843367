import React, { Component, createRef } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

class Number extends Component {
  state = {
    isVisible: false,
    count: 0
  }

  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ isVisible: true }, this.animateCounting);
    }
  }

  animateCounting = () => {
    const finalCount = parseInt(this.props.value);
    const distance = Math.abs(finalCount - this.state.count);
    const baseIntervalTime = 10; // Base interval time in milliseconds
    const maxIntervalTime = 160; // Maximum interval time in milliseconds
    const intervalTime = Math.max(baseIntervalTime, maxIntervalTime - distance * 2); // Calculate interval time based on distance
  
    const countingInterval = setInterval(() => {
      if (this.state.count < finalCount) {
        this.setState(prevState => ({ count: prevState.count + 1 }));
      } else if (this.state.count > finalCount) {
        this.setState(prevState => ({ count: prevState.count - 1 }));
      } else {
        clearInterval(countingInterval);
      }
    }, intervalTime);
  }

  render() {
    const { count } = this.state;
    const node = createRef();
  
    return (
      <VisibilitySensor onChange={this.onVisibilityChange}>
        {({ isVisible }) => (
          <div className="nbEffect" ref={node}>
            {count}
          </div>
        )}
      </VisibilitySensor>
    );
  }
  
}

export default Number;