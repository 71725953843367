import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyByTxQHpk5S1K0bL6gWxsO5hBPRKEF0xnc",
  authDomain: "unlock-b3d3d.firebaseapp.com",
  databaseURL: "https://unlock-b3d3d-default-rtdb.firebaseio.com",
  projectId: "unlock-b3d3d",
  storageBucket: "unlock-b3d3d.appspot.com",
  messagingSenderId: "466593643931",
  appId: "1:466593643931:web:240476e19fa11d3ac9353e",
  measurementId: "G-NNBNL824ST"
};

const app = initializeApp(firebaseConfig);

export const db = getDatabase(app);
