import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

// const a = await fetch('https://metalyde.com/wp/wp-json/wp/v2/pages/27')
// const ABOUT = await a.json()

class TextEffect extends Component {

  render() {
    return (
      <div class="m-scroll__title">
        <div>
          <h1>The power of loyalty&nbsp;</h1>
          <h1>The power of loyalty&nbsp;</h1>
        </div>
      </div>
    );
  }

}

export default TextEffect;
