import React from 'react';

function IHome() {
  return (
    <div id="i-home" >

<svg width="812" height="812" viewBox="0 0 812 812" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Frame 1">
<g id="Group 138">
<path id="Vector" d="M118 745.808L216.878 133H596.021L695 747L118 745.808Z" fill="#F59FF7"/>
<path id="Vector_2" d="M406.5 790C565.834 790 695 770.524 695 746.5C695 722.476 565.834 703 406.5 703C247.166 703 118 722.476 118 746.5C118 770.524 247.166 790 406.5 790Z" fill="black"/>
<path id="Vector_3" d="M406.5 151C511.158 151 596 142.493 596 132C596 121.507 511.158 113 406.5 113C301.842 113 217 121.507 217 132C217 142.493 301.842 151 406.5 151Z" fill="black"/>
<g id="Group 5" className='float-4'>
<path id="Vector_4" d="M486.325 459.712C515.969 446.477 535.418 425.212 529.766 412.216C524.114 399.22 495.502 399.414 465.858 412.649C436.214 425.884 416.765 447.148 422.417 460.144C428.069 473.14 456.681 472.947 486.325 459.712Z" fill="#1C1C19"/>
<path id="Vector_5" d="M454.135 469.804C451.229 470.178 448.304 470.382 445.374 470.414C433.025 470.494 424.657 466.938 421.803 460.396C416.014 447.128 435.658 425.393 465.58 412.032C495.516 398.657 524.575 398.617 530.363 411.926C533.205 418.467 530.154 427.132 521.76 436.3C513.365 445.469 501.003 453.868 486.585 460.303C476.271 465.021 465.343 468.22 454.135 469.804ZM465.921 412.629L466.183 413.239C436.915 426.309 417.573 447.22 423.073 459.866C425.692 465.89 433.628 469.154 445.427 469.154C457.226 469.154 471.853 465.518 486.114 459.149C500.375 452.78 512.711 444.381 520.856 435.464C529.001 426.548 531.843 418.56 529.224 412.536C523.724 399.891 495.451 400.236 466.183 413.306L465.921 412.629Z" fill="#1C1C19"/>
<path id="Vector_6" d="M482.481 450.516C512.125 437.281 531.574 416.017 525.922 403.021C520.271 390.025 491.658 390.218 462.014 403.453C432.371 416.688 412.921 437.953 418.573 450.949C424.225 463.945 452.838 463.751 482.481 450.516Z" fill="#9E93FF"/>
<path id="Vector_7" d="M450.232 461.352C433.784 463.435 421.147 460.131 417.376 451.48C414.429 444.673 417.52 435.77 426.11 426.389C434.426 417.286 446.997 408.701 461.468 402.226C492.242 388.493 521.078 388.599 527.115 402.478C533.152 416.357 513.771 437.972 482.996 451.719C472.582 456.49 461.55 459.734 450.232 461.352ZM494.389 395.393C483.396 396.942 472.677 400.069 462.555 404.681C448.372 411.023 436.115 419.369 428.022 428.22C420.165 436.778 417.271 444.66 419.772 450.432C425.01 462.533 453.519 462.015 481.962 449.317C510.405 436.619 529.996 415.668 524.745 403.566C521.523 396.149 509.646 393.469 494.389 395.393Z" fill="#1C1C19"/>
<path id="Vector_8" d="M524.889 401.125C526.277 403.142 530.402 414.035 530.402 414.035Z" fill="white"/>
<path id="Vector_9" d="M529.786 414.274C528.372 410.533 525.412 403.049 524.352 401.497L525.425 400.74C526.84 402.81 530.834 413.346 531.004 413.797L529.786 414.274Z" fill="#1D1D1B"/>
<path id="Vector_10" d="M460.447 435.491L457.998 436.712L456.793 434.204L451.83 436.659L450.625 434.191L455.523 431.736L454.305 429.228L456.754 428.007L456.151 426.76L458.6 425.539L457.998 424.305L462.935 421.837L464.742 425.566L462.306 426.787L462.908 428.034L460.46 429.255L461.664 431.736L464.087 430.529L464.703 431.789L466.994 430.635C467.035 430.596 467.064 430.548 467.081 430.495C467.097 430.441 467.098 430.384 467.086 430.329C466.772 429.653 466.444 429.003 466.117 428.313L465.986 428.047L468.448 426.813L467.243 424.332L469.666 423.125L468.448 420.617L470.91 419.396L470.307 418.162L477.706 414.46L478.296 415.694L480.771 414.46L481.373 415.707L483.822 414.487L485.04 416.981L489.937 414.54L491.142 417.021L486.284 419.449L487.502 421.957L485.013 423.191L485.616 424.438L483.18 425.646L483.783 426.88L478.872 429.334L477.065 425.632L479.487 424.425L478.872 423.164L481.308 421.944L480.103 419.462L477.693 420.67L477.091 419.436L474.616 420.67L475.821 423.138L473.345 424.359L474.563 426.853L472.115 428.074L473.332 430.568L470.87 431.789L471.473 433.036L464.074 436.725L463.485 435.504L461.023 436.725L460.447 435.491Z" fill="#1D1D1B"/>
</g>
<g id="Group 7" className='float-3'>
<path id="Vector_11" d="M374.645 268.032C375.263 236.582 364.876 210.868 351.446 210.597C338.016 210.326 326.629 235.601 326.011 267.051C325.393 298.501 335.78 324.215 349.21 324.486C362.64 324.757 374.028 299.481 374.645 268.032Z" fill="#1D1D1B"/>
<path id="Vector_12" d="M349.43 325.138H349.204C335.399 324.858 324.769 298.803 325.359 267.051C325.661 251.791 328.484 237.409 333.329 226.664C338.173 215.919 344.712 209.841 351.438 209.968C365.181 210.235 375.873 236.303 375.283 268.055C374.694 299.808 363.085 325.138 349.43 325.138ZM351.238 211.227C345.088 211.227 339.152 216.885 334.508 227.198C329.739 237.778 326.978 251.944 326.652 267.076C326.037 298.116 336.165 323.599 349.242 323.866H349.443C362.42 323.866 373.426 298.917 374.041 268.03C374.656 237.142 364.515 211.494 351.451 211.239L351.238 211.227Z" fill="#1D1D1B"/>
<path id="Vector_13" d="M365.208 267.978C365.825 236.528 355.439 210.814 342.009 210.543C328.579 210.272 317.191 235.548 316.573 266.997C315.956 298.447 326.342 324.161 339.772 324.432C353.202 324.703 364.59 299.427 365.208 267.978Z" fill="#0EB17F"/>
<path id="Vector_14" d="M339.992 325.723H339.753C325.421 325.431 314.653 299.629 315.331 266.987C315.632 251.6 318.481 237.168 323.363 226.359C328.383 215.194 335.035 209.179 342.05 209.281C356.382 209.561 367.15 235.362 366.472 268.017C365.795 300.672 354.224 325.723 339.992 325.723ZM341.799 211.812C335.913 211.812 330.178 217.343 325.647 227.414C320.903 237.918 318.117 251.995 317.841 267.038C317.251 297.213 327.304 322.925 339.804 323.18H339.992C352.416 323.18 363.373 298.002 363.962 267.966C364.552 237.931 354.5 212.015 342 211.824L341.799 211.812Z" fill="#1D1D1B"/>
<path id="Vector_15" d="M330.654 270.713L328.094 270.789L327.742 256.75L330.265 256.687L330.102 250.074L332.825 249.998L332.662 243.119L335.31 243.055L335.486 249.935L338.109 249.871L338.272 256.471L340.97 256.407L340.807 249.795L343.467 249.731L343.304 242.852L345.952 242.775L346.128 249.655L348.801 249.591L348.964 256.204L351.587 256.127L351.939 270.166L349.265 270.242L349.429 276.982L346.818 277.058L346.981 283.658L344.358 283.721L344.534 290.614L341.811 290.677L341.974 297.29L339.313 297.353L339.15 290.741L336.414 290.817L336.239 283.938L333.653 284.001L333.49 277.389L330.83 277.465L330.654 270.713Z" fill="black"/>
</g>
<g id="Group 8">
<path id="Vector_16" d="M585.45 767.712C621.385 768.967 650.951 757.212 651.486 741.458C652.022 725.703 623.325 711.915 587.39 710.66C551.455 709.406 521.89 721.16 521.354 736.914C520.818 752.669 549.515 766.457 585.45 767.712Z" fill="#1D1D1B"/>
<path id="Vector_17" d="M520.604 737.162L520.608 736.897C521.16 720.703 551.119 708.669 587.401 709.896C604.836 710.506 621.227 714.062 633.426 719.927C645.626 725.793 652.463 733.568 652.204 741.459C651.668 757.58 621.693 769.688 585.411 768.46C549.13 767.233 520.374 753.185 520.604 737.162ZM650.769 741.202C650.873 733.986 644.505 726.925 632.796 721.303C620.784 715.528 604.64 712.05 587.35 711.412C551.883 710.167 522.586 721.622 522.06 736.963L522.057 737.198C521.838 752.425 550.169 765.761 585.461 767.003C620.754 768.245 650.24 756.778 650.751 741.452L650.769 741.202Z" fill="#1D1D1B"/>
<path id="Vector_18" d="M585.671 756.639C621.606 757.894 651.171 746.139 651.707 730.385C652.243 714.631 623.546 700.842 587.611 699.587C551.676 698.333 522.111 710.087 521.575 725.842C521.039 741.596 549.736 755.385 585.671 756.639Z" fill="#0EB17F"/>
<path id="Vector_19" d="M520.094 726.078L520.098 725.798C520.674 708.986 550.345 696.785 587.642 698.13C605.223 698.743 621.671 702.329 633.943 708.24C646.619 714.318 653.382 722.225 653.147 730.455C652.586 747.268 622.915 759.469 585.603 758.123C548.291 756.778 519.854 742.778 520.094 726.078ZM650.259 730.118C650.359 723.211 644.133 716.388 632.698 710.902C620.773 705.159 604.731 701.652 587.542 701.075C553.063 699.874 523.505 711.237 523.004 725.9L523.001 726.121C522.792 740.7 551.384 753.98 585.704 755.179C620.023 756.377 649.813 745.018 650.241 730.353L650.259 730.118Z" fill="#1D1D1B"/>
<path id="Vector_20" d="M583.127 716.049L583.083 713.043L599.135 712.867L599.165 715.828L606.725 715.748L606.766 718.945L614.632 718.87L614.66 721.978L606.794 722.068L606.823 725.147L599.277 725.227L599.304 728.395L606.865 728.315L606.892 731.438L614.758 731.362L614.801 734.471L606.935 734.561L606.962 737.699L599.402 737.779L599.445 740.858L583.393 741.034L583.351 737.896L575.646 737.974L575.602 734.909L568.056 734.99L568.028 731.911L560.148 732.001L560.121 728.804L552.56 728.884L552.533 725.761L560.093 725.681L560.052 722.469L567.918 722.379L567.889 719.344L575.449 719.264L575.407 716.141L583.127 716.049Z" fill="black"/>
</g>
<g id="Group 6" className='float-5'>
<path id="Vector_21" d="M510.329 187.747C510.947 156.298 500.56 130.583 487.13 130.312C473.7 130.042 462.312 155.317 461.695 186.766C461.077 218.216 471.463 243.93 484.893 244.201C498.323 244.472 509.711 219.197 510.329 187.747Z" fill="#1D1D1B"/>
<path id="Vector_22" d="M485.13 244.846H484.904C471.099 244.566 460.469 218.511 461.059 186.759C461.36 171.499 464.183 157.117 469.028 146.385C473.872 135.652 480.26 129.625 486.937 129.625H487.163C500.968 129.905 511.598 155.96 511.008 187.712C510.707 202.972 507.883 217.354 503.038 228.099C498.194 238.844 491.869 244.846 485.13 244.846ZM462.376 186.771C461.761 217.824 471.889 243.295 484.966 243.562C491.241 243.714 497.203 238.018 501.896 227.603C506.59 217.189 509.426 202.857 509.753 187.725C510.368 156.672 500.24 131.202 487.163 130.935C480.963 130.846 474.926 136.479 470.233 146.894C465.539 157.308 462.703 171.639 462.376 186.771Z" fill="#1D1D1B"/>
<path id="Vector_23" d="M500.891 187.693C501.509 156.244 491.123 130.529 477.693 130.259C464.263 129.988 452.875 155.263 452.257 186.713C451.639 218.162 462.026 243.877 475.456 244.148C488.886 244.418 500.274 219.143 500.891 187.693Z" fill="#0EB17F"/>
<path id="Vector_24" d="M475.691 245.444H475.453C461.108 245.164 450.353 219.363 451.03 186.708C451.332 171.334 454.18 156.901 459.05 146.08C464.07 134.928 470.709 128.875 477.737 129.002C492.082 129.294 502.837 155.096 502.159 187.738C501.858 203.125 499.009 217.557 494.14 228.366C489.17 239.378 482.631 245.444 475.691 245.444ZM453.54 186.721C452.938 216.909 463.003 242.672 475.503 242.863C481.514 243.015 487.275 237.458 491.818 227.273C496.549 216.769 499.348 202.692 499.612 187.649C500.214 157.473 490.149 131.698 477.649 131.507C471.738 131.405 465.877 136.924 461.334 147.097C456.603 157.613 453.804 171.677 453.54 186.721Z" fill="#1D1D1B"/>
<path id="Vector_25" d="M466.354 190.434L463.781 190.497L463.43 176.459L465.965 176.395L465.802 169.795L468.525 169.719L468.349 162.84L471.01 162.763L471.186 169.656L473.796 169.579L473.972 176.192L476.657 176.115L476.494 169.516L479.167 169.439L478.992 162.56L481.652 162.496L481.828 169.376L484.489 169.312L484.664 175.912L487.287 175.848L487.626 189.887L484.953 189.951L485.129 196.703L482.506 196.766L482.669 203.379L480.058 203.442L480.222 210.322L477.498 210.398L477.661 216.998L475.013 217.074L474.85 210.462L472.102 210.538L471.939 203.646L469.353 203.722L469.178 197.11L466.53 197.173L466.354 190.434Z" fill="black"/>
</g>
<g id="Group 9" className='float'>
<path id="Vector_26" d="M362.081 495.483L362.254 489.197H362.441V488.589H393.518V577.257H305.047V547.815L312.993 546.318L314.045 526.23L322.049 524.258L323.116 516.816L331.307 514.563L332.143 507.432L340.565 505.045" fill="#FFBAFA"/>
<path id="Vector_27" d="M474.146 546.08L472.892 526.185L464.991 524.006L463.738 516.786L455.647 514.311L454.598 507.387L446.288 504.793L445.443 498.077L424.349 495.275L424.16 488.589H393.518V577.257H481.989V547.77L474.146 546.08Z" fill="#F7EB27"/>
<path id="Vector_28" d="M393.518 667.874H424.057V667.507V659.248H445.315L446.131 650.842L454.611 650.167L455.66 641.527L463.79 640.94L465.029 632.182L472.955 631.8L474.077 610.074L482.95 609.003L481.945 577.257H393.518V667.874Z" fill="#FFBAFA"/>
<path id="Vector_29" d="M323.736 632.387L313.959 631.83V610.573H305.047V577.257H393.518V667.874H362.34V659.952H362.254V660.04L341.301 659.292L340.623 650.93L332.086 650.211L331.206 641.629L323.015 640.984L323.736 632.387Z" fill="#F7EB27"/>
<path id="Vector_30" d="M426.213 486.641H362.744V495.41H426.213V486.641Z" fill="black"/>
<path id="Vector_31" d="M426.213 659.104H362.744V668.848H426.213V659.104Z" fill="black"/>
<path id="Vector_32" d="M303.123 545.103V610.386H312.739V545.103H303.123Z" fill="black"/>
<path id="Vector_33" d="M362.745 495.41H340.627V505.154H362.745V495.41Z" fill="black"/>
<path id="Vector_34" d="M362.745 650.335H340.627V660.079H362.745V650.335Z" fill="black"/>
<path id="Vector_35" d="M312.74 523.667V545.103H322.357V523.667H312.74Z" fill="black"/>
<path id="Vector_36" d="M312.74 609.412V631.822H322.357V609.412H312.74Z" fill="black"/>
<path id="Vector_37" d="M340.627 505.154H331.973V513.923H340.627V505.154Z" fill="black"/>
<path id="Vector_38" d="M331.972 514.897H322.355V523.667H331.972V514.897Z" fill="black"/>
<path id="Vector_39" d="M331.972 631.822H322.355V640.592H331.972V631.822Z" fill="black"/>
<path id="Vector_40" d="M340.627 640.592H331.973V650.335H340.627V640.592Z" fill="black"/>
<path id="Vector_41" d="M362.744 495.41H426.213V485.666H362.744V495.41Z" fill="black"/>
<path id="Vector_42" d="M362.744 668.848H426.213V659.105H362.744V668.848Z" fill="black"/>
<path id="Vector_43" d="M476.219 545.103V609.412H484.874V545.103H476.219Z" fill="black"/>
<path id="Vector_44" d="M426.213 504.179H447.369V495.41H426.213V504.179Z" fill="black"/>
<path id="Vector_45" d="M426.213 660.079H447.369V650.335H426.213V660.079Z" fill="black"/>
<path id="Vector_46" d="M466.602 523.667V545.103H476.218V523.667H466.602Z" fill="black"/>
<path id="Vector_47" d="M466.602 609.412V631.822H476.218V609.412H466.602Z" fill="black"/>
<path id="Vector_48" d="M447.37 513.923H456.986V504.179H447.37V513.923Z" fill="black"/>
<path id="Vector_49" d="M456.985 523.667H466.602V513.923H456.985V523.667Z" fill="black"/>
<path id="Vector_50" d="M456.985 640.592H466.602V631.822H456.985V640.592Z" fill="black"/>
<path id="Vector_51" d="M447.37 650.335H456.986V640.591H447.37V650.335Z" fill="black"/>
<path id="Vector_52" d="M360.821 550.949H349.281V563.616H360.821V550.949Z" fill="black"/>
<path id="Vector_53" d="M438.716 550.949H427.176V563.616H438.716V550.949Z" fill="black"/>
<path id="Vector_54" d="M393.962 625.002C380.575 624.974 367.745 619.522 358.287 609.84C348.829 600.159 343.516 587.039 343.512 573.36H351.084C351.29 584.851 355.903 595.799 363.928 603.851C371.954 611.902 382.751 616.413 393.998 616.413C405.245 616.413 416.043 611.902 424.068 603.851C432.094 595.799 436.706 584.851 436.913 573.36H444.484C444.484 587.053 439.162 600.186 429.688 609.871C420.213 619.555 407.363 624.998 393.962 625.002Z" fill="black"/>
</g>
<g id="stars">
<path id="Vector_55" d="M537.765 282.997C543.076 282.997 547.381 278.416 547.381 272.766C547.381 267.115 543.076 262.535 537.765 262.535C532.454 262.535 528.148 267.115 528.148 272.766C528.148 278.416 532.454 282.997 537.765 282.997Z" fill="#F1F2ED"/>
<path id="Vector_56" d="M496.894 350.228C498.222 350.228 499.298 348.92 499.298 347.305C499.298 345.691 498.222 344.382 496.894 344.382C495.567 344.382 494.49 345.691 494.49 347.305C494.49 348.92 495.567 350.228 496.894 350.228Z" fill="#F1F2ED"/>
<path id="Vector_57" d="M440.158 296.638C441.486 296.638 442.562 295.765 442.562 294.689C442.562 293.613 441.486 292.74 440.158 292.74C438.83 292.74 437.754 293.613 437.754 294.689C437.754 295.765 438.83 296.638 440.158 296.638Z" fill="#F1F2ED"/>
<path id="Vector_58" d="M421.406 289.817C423 289.817 424.291 288.727 424.291 287.381C424.291 286.036 423 284.945 421.406 284.945C419.813 284.945 418.521 286.036 418.521 287.381C418.521 288.727 419.813 289.817 421.406 289.817Z" fill="#F1F2ED"/>
<path id="Vector_59" d="M392.076 372.639C393.404 372.639 394.48 371.33 394.48 369.716C394.48 368.101 393.404 366.793 392.076 366.793C390.748 366.793 389.672 368.101 389.672 369.716C389.672 371.33 390.748 372.639 392.076 372.639Z" fill="#F1F2ED"/>
<path id="Vector_60" d="M384.383 426.229C385.71 426.229 386.787 425.139 386.787 423.793C386.787 422.448 385.71 421.358 384.383 421.358C383.055 421.358 381.979 422.448 381.979 423.793C381.979 425.139 383.055 426.229 384.383 426.229Z" fill="#F1F2ED"/>
<path id="Vector_61" d="M325.722 436.948C327.05 436.948 328.127 435.639 328.127 434.024C328.127 432.41 327.05 431.101 325.722 431.101C324.395 431.101 323.318 432.41 323.318 434.024C323.318 435.639 324.395 436.948 325.722 436.948Z" fill="#F1F2ED"/>
<path id="Vector_62" d="M287.257 625.976C288.054 625.976 288.699 625.103 288.699 624.027C288.699 622.951 288.054 622.078 287.257 622.078C286.46 622.078 285.814 622.951 285.814 624.027C285.814 625.103 286.46 625.976 287.257 625.976Z" fill="#F1F2ED"/>
<path id="Vector_63" d="M264.658 697.105C265.72 697.105 266.581 696.233 266.581 695.156C266.581 694.08 265.72 693.208 264.658 693.208C263.595 693.208 262.734 694.08 262.734 695.156C262.734 696.233 263.595 697.105 264.658 697.105Z" fill="#F1F2ED"/>
<path id="Vector_64" d="M175.706 632.797C176.503 632.797 177.149 632.142 177.149 631.335C177.149 630.528 176.503 629.874 175.706 629.874C174.909 629.874 174.264 630.528 174.264 631.335C174.264 632.142 174.909 632.797 175.706 632.797Z" fill="#F1F2ED"/>
<path id="Vector_65" d="M240.617 458.384C241.679 458.384 242.54 457.511 242.54 456.435C242.54 455.359 241.679 454.486 240.617 454.486C239.554 454.486 238.693 455.359 238.693 456.435C238.693 457.511 239.554 458.384 240.617 458.384Z" fill="#F1F2ED"/>
<path id="Vector_66" d="M244.944 327.818C245.741 327.818 246.387 326.945 246.387 325.869C246.387 324.793 245.741 323.92 244.944 323.92C244.148 323.92 243.502 324.793 243.502 325.869C243.502 326.945 244.148 327.818 244.944 327.818Z" fill="#F1F2ED"/>
<path id="Vector_67" d="M257.927 188.482C258.989 188.482 259.85 187.828 259.85 187.021C259.85 186.214 258.989 185.559 257.927 185.559C256.865 185.559 256.004 186.214 256.004 187.021C256.004 187.828 256.865 188.482 257.927 188.482Z" fill="#F1F2ED"/>
<path id="Vector_68" d="M409.867 186.534C410.929 186.534 411.79 185.661 411.79 184.585C411.79 183.509 410.929 182.636 409.867 182.636C408.804 182.636 407.943 183.509 407.943 184.585C407.943 185.661 408.804 186.534 409.867 186.534Z" fill="#F1F2ED"/>
<path id="Vector_69" d="M379.093 234.278C380.155 234.278 381.016 233.405 381.016 232.329C381.016 231.253 380.155 230.38 379.093 230.38C378.031 230.38 377.17 231.253 377.17 232.329C377.17 233.405 378.031 234.278 379.093 234.278Z" fill="#F1F2ED"/>
<path id="Vector_70" d="M451.216 244.022C452.278 244.022 453.14 243.149 453.14 242.073C453.14 240.997 452.278 240.124 451.216 240.124C450.154 240.124 449.293 240.997 449.293 242.073C449.293 243.149 450.154 244.022 451.216 244.022Z" fill="#F1F2ED"/>
<path id="Vector_71" d="M561.806 191.405C562.868 191.405 563.729 190.533 563.729 189.457C563.729 188.38 562.868 187.508 561.806 187.508C560.744 187.508 559.883 188.38 559.883 189.457C559.883 190.533 560.744 191.405 561.806 191.405Z" fill="#F1F2ED"/>
<path id="Vector_72" d="M491.607 261.56C492.669 261.56 493.53 260.906 493.53 260.099C493.53 259.292 492.669 258.637 491.607 258.637C490.545 258.637 489.684 259.292 489.684 260.099C489.684 260.906 490.545 261.56 491.607 261.56Z" fill="#F1F2ED"/>
<path id="Vector_73" d="M394.96 475.922C395.757 475.922 396.403 475.05 396.403 473.974C396.403 472.897 395.757 472.025 394.96 472.025C394.163 472.025 393.518 472.897 393.518 473.974C393.518 475.05 394.163 475.922 394.96 475.922Z" fill="#F1F2ED"/>
<path id="Vector_74" d="M579.117 358.023C580.179 358.023 581.04 357.369 581.04 356.562C581.04 355.754 580.179 355.1 579.117 355.1C578.054 355.1 577.193 355.754 577.193 356.562C577.193 357.369 578.054 358.023 579.117 358.023Z" fill="#F1F2ED"/>
<path id="Vector_75" d="M571.423 461.307C572.485 461.307 573.347 460.434 573.347 459.358C573.347 458.282 572.485 457.409 571.423 457.409C570.361 457.409 569.5 458.282 569.5 459.358C569.5 460.434 570.361 461.307 571.423 461.307Z" fill="#F1F2ED"/>
<path id="Vector_76" d="M498.819 494.436C499.616 494.436 500.262 493.781 500.262 492.974C500.262 492.167 499.616 491.512 498.819 491.512C498.023 491.512 497.377 492.167 497.377 492.974C497.377 493.781 498.023 494.436 498.819 494.436Z" fill="#F1F2ED"/>
<path id="Vector_77" d="M487.759 488.589C488.821 488.589 489.683 487.717 489.683 486.641C489.683 485.564 488.821 484.692 487.759 484.692C486.697 484.692 485.836 485.564 485.836 486.641C485.836 487.717 486.697 488.589 487.759 488.589Z" fill="#F1F2ED"/>
<path id="Vector_78" d="M556.037 587.001C557.099 587.001 557.96 586.347 557.96 585.539C557.96 584.732 557.099 584.078 556.037 584.078C554.974 584.078 554.113 584.732 554.113 585.539C554.113 586.347 554.974 587.001 556.037 587.001Z" fill="#F1F2ED"/>
<path id="Vector_79" d="M369.958 683.464C370.755 683.464 371.401 682.81 371.401 682.002C371.401 681.195 370.755 680.541 369.958 680.541C369.161 680.541 368.516 681.195 368.516 682.002C368.516 682.81 369.161 683.464 369.958 683.464Z" fill="#F1F2ED"/>
<path id="Vector_80" d="M575.268 696.131C575.799 696.131 576.23 695.258 576.23 694.182C576.23 693.106 575.799 692.233 575.268 692.233C574.737 692.233 574.307 693.106 574.307 694.182C574.307 695.258 574.737 696.131 575.268 696.131Z" fill="#F1F2ED"/>
<path id="Vector_81" d="M556.037 152.43C557.099 152.43 557.96 151.558 557.96 150.482C557.96 149.405 557.099 148.533 556.037 148.533C554.974 148.533 554.113 149.405 554.113 150.482C554.113 151.558 554.974 152.43 556.037 152.43Z" fill="#F1F2ED"/>
<path id="Vector_82" d="M272.352 590.898C273.945 590.898 275.237 589.808 275.237 588.463C275.237 587.117 273.945 586.027 272.352 586.027C270.758 586.027 269.467 587.117 269.467 588.463C269.467 589.808 270.758 590.898 272.352 590.898Z" fill="#F1F2ED"/>
<path id="Vector_83" d="M328.607 355.1C329.935 355.1 331.011 354.01 331.011 352.664C331.011 351.319 329.935 350.228 328.607 350.228C327.279 350.228 326.203 351.319 326.203 352.664C326.203 354.01 327.279 355.1 328.607 355.1Z" fill="#F1F2ED"/>
<path id="Vector_84" d="M240.617 248.893C242.211 248.893 243.502 248.021 243.502 246.945C243.502 245.868 242.211 244.996 240.617 244.996C239.024 244.996 237.732 245.868 237.732 246.945C237.732 248.021 239.024 248.893 240.617 248.893Z" fill="#F1F2ED"/>
<path id="Vector_85" d="M226.674 148.533C228.001 148.533 229.078 147.442 229.078 146.097C229.078 144.752 228.001 143.661 226.674 143.661C225.346 143.661 224.27 144.752 224.27 146.097C224.27 147.442 225.346 148.533 226.674 148.533Z" fill="#F1F2ED"/>
<path id="Vector_86" d="M288.219 396.024C291.14 396.024 293.508 393.624 293.508 390.665C293.508 387.705 291.14 385.306 288.219 385.306C285.298 385.306 282.93 387.705 282.93 390.665C282.93 393.624 285.298 396.024 288.219 396.024Z" fill="#F1F2ED"/>
<path id="Vector_87" d="M569.98 530.487C572.902 530.487 575.269 527.87 575.269 524.641C575.269 521.412 572.902 518.795 569.98 518.795C567.059 518.795 564.691 521.412 564.691 524.641C564.691 527.87 567.059 530.487 569.98 530.487Z" fill="#F1F2ED"/>
<path id="Vector_88" d="M318.99 189.457C321.911 189.457 324.279 187.057 324.279 184.098C324.279 181.138 321.911 178.739 318.99 178.739C316.069 178.739 313.701 181.138 313.701 184.098C313.701 187.057 316.069 189.457 318.99 189.457Z" fill="#F1F2ED"/>
</g>
<g id="Group 2">
<path id="Vector_89" d="M570.403 720.837C570.563 705.074 541.546 691.991 505.592 691.616C469.637 691.241 440.36 703.715 440.2 719.478C440.04 735.241 469.057 748.324 505.011 748.699C540.966 749.074 570.243 736.6 570.403 720.837Z" fill="#1D1D1B"/>
<path id="Vector_90" d="M506.57 749.416H505C487.557 749.239 471.103 746.073 458.762 740.506C446.421 734.939 439.386 727.383 439.473 719.46C439.561 711.536 446.552 704.187 459.184 698.84C471.815 693.494 488.124 690.725 505.596 690.902C541.935 691.285 571.296 704.732 571.136 720.873C570.977 736.75 542.153 749.416 506.57 749.416ZM504.011 692.331C487.295 692.331 471.641 695.099 459.751 700.166C447.86 705.232 440.971 712.154 440.971 719.474C440.971 726.794 447.439 733.775 459.402 739.166C471.364 744.556 487.775 747.767 505.058 748.002C540.568 748.415 569.567 736.22 569.726 720.873C569.886 705.527 541.135 692.772 505.625 692.39L504.011 692.331Z" fill="#1D1D1B"/>
<path id="Vector_91" d="M570.682 709.75C570.842 693.987 541.825 680.905 505.871 680.53C469.916 680.155 440.64 692.629 440.48 708.392C440.319 724.155 469.336 737.237 505.291 737.612C541.245 737.988 570.522 725.513 570.682 709.75Z" fill="#FB7243"/>
<path id="Vector_92" d="M506.875 739.092H505.276C467.949 738.709 438.849 725.218 439.008 708.384C439.168 691.55 468.516 678.722 505.872 679.075C523.445 679.252 539.972 682.433 552.386 688.045C565.192 693.803 572.198 701.536 572.111 709.783C572.023 718.031 564.843 725.601 551.95 731.095C539.813 736.264 523.823 739.092 506.875 739.092ZM504.346 681.992C470.507 681.992 442.075 693.951 441.843 708.399C441.698 723.127 470.725 735.778 505.232 736.132C522.399 736.323 538.664 733.554 550.772 728.37C562.561 723.348 569.087 716.735 569.16 709.739C569.232 702.743 562.837 695.983 551.15 690.711C539.086 685.276 522.98 682.183 505.785 682.006H504.331L504.346 681.992Z" fill="#1D1D1B"/>
<path id="Vector_93" d="M509.608 697.176L509.783 694.157L493.75 693.259L493.59 696.234L486.046 695.806L485.872 698.988L478.008 698.546L477.834 701.668L485.697 702.11L485.523 705.173L493.067 705.601L492.892 708.767L485.348 708.34L485.174 711.462L477.325 711.02L477.15 714.128L485.014 714.585L484.84 717.707L492.384 718.134L492.209 721.212L508.242 722.111L508.416 718.974L516.12 719.415L516.28 716.337L523.824 716.764L523.998 713.701L531.848 714.143L532.022 710.947L539.566 711.374L539.74 708.252L532.197 707.839L532.371 704.629L524.522 704.187L524.682 701.153L517.138 700.726L517.312 697.603L509.608 697.176Z" fill="black"/>
</g>
<g id="Group 11" className='float-2'>
<g id="Calque_1" clipPath="url(#clip0_233_4)">
<path id="Vector_94" d="M378.937 528.623C385.853 517.752 369.395 496.979 342.177 482.226C314.959 467.473 287.288 464.326 280.373 475.197C273.458 486.067 289.916 506.84 317.134 521.593C344.352 536.346 372.022 539.494 378.937 528.623Z" fill="#1D1D1B" stroke="#1D1D1B" strokeMiterlimit="10"/>
<path id="Vector_95" d="M383.912 521.051C390.827 510.18 374.369 489.408 347.151 474.655C319.933 459.901 292.263 456.754 285.348 467.625C278.432 478.496 294.891 499.268 322.108 514.022C349.326 528.775 376.997 531.922 383.912 521.051Z" fill="#F5EB57" stroke="#1D1D1B" strokeWidth="2" strokeMiterlimit="10"/>
<path id="Vector_96" d="M342.857 487.73L344.285 485.718L332.463 478.629L331.07 480.621L325.502 477.284L323.99 479.429L318.199 475.953L316.735 478.043L322.525 481.52L321.067 483.585L326.625 486.915L325.136 489.033L319.579 485.703L318.089 487.804L312.298 484.328L310.834 486.419L316.624 489.895L315.144 492.004L320.701 495.334L319.243 497.399L331.065 504.488L332.546 502.379L338.22 505.782L339.67 503.726L345.237 507.064L346.695 504.999L352.485 508.475L353.998 506.33L359.547 509.669L361.029 507.576L355.462 504.239L356.983 502.084L351.193 498.608L352.626 496.571L347.059 493.233L348.533 491.15L342.857 487.73Z" fill="black"/>
</g>
</g>
<g id="Group 1">
<path id="Vector_97" d="M314.38 784.658C314.524 770.457 288.382 758.671 255.99 758.333C223.598 757.995 197.222 769.233 197.078 783.434C196.933 797.635 223.075 809.422 255.467 809.76C287.86 810.098 314.236 798.859 314.38 784.658Z" fill="#1D1D1B"/>
<path id="Vector_98" d="M256.855 810.406H255.454C239.739 810.247 224.902 807.394 213.797 802.445C202.693 797.496 196.341 790.622 196.407 783.471C196.472 776.319 202.784 769.711 214.177 764.894C225.57 760.078 240.224 757.557 255.991 757.742C271.758 757.928 286.542 760.754 297.647 765.704C308.752 770.653 315.09 777.5 315.09 784.638C314.881 798.982 288.899 810.406 256.855 810.406ZM254.55 758.976C239.504 758.976 225.387 761.458 214.688 766.022C203.989 770.587 197.782 776.836 197.664 783.417C197.546 789.999 203.491 796.315 214.269 801.171C225.046 806.027 239.831 808.907 255.415 809.132C287.341 809.451 313.532 798.517 313.676 784.691C313.676 778.057 307.835 771.807 297.058 766.951C286.28 762.095 271.509 759.202 255.925 758.99H254.498L254.55 758.976Z" fill="#1D1D1B"/>
<path id="Vector_99" d="M314.62 774.683C314.765 760.482 288.622 748.696 256.23 748.358C223.838 748.02 197.462 759.258 197.318 773.459C197.174 787.661 223.316 799.447 255.708 799.785C288.1 800.123 314.476 788.884 314.62 774.683Z" fill="#9F94FC"/>
<path id="Vector_100" d="M257.143 801.091H255.689C222.061 800.733 195.844 788.592 196.001 773.426C196.158 758.26 222.624 746.65 256.239 747.008C272.085 747.181 286.974 750.047 298.145 755.089C309.682 760.29 315.994 767.243 315.915 774.673C315.837 782.104 309.367 788.924 297.752 793.887C286.817 798.544 272.438 801.091 257.143 801.091ZM254.851 749.662C224.352 749.662 198.738 760.423 198.607 773.453C198.476 786.483 224.627 798.106 255.703 798.438C271.181 798.597 285.822 796.116 296.743 791.445C307.351 786.92 313.231 780.949 313.296 774.647C313.362 768.344 307.6 762.254 297.084 757.517C286.215 752.621 271.692 749.834 256.2 749.662H254.851Z" fill="#1D1D1B"/>
<path id="Vector_101" d="M259.605 763.329L259.749 760.622L245.305 759.799L245.161 762.479L238.365 762.095L238.207 764.974L231.136 764.576L230.979 767.376L238.05 767.774L237.906 770.547L244.703 770.932L244.546 773.771L237.749 773.386L237.592 776.212L230.507 775.814L230.363 778.614L237.435 779.012L237.278 781.838L244.074 782.223L243.93 784.983L258.361 785.806L258.518 782.98L265.459 783.364L265.616 780.604L272.413 780.989L272.557 778.216L279.641 778.614L279.798 775.735L286.595 776.12L286.752 773.32L279.942 772.935L280.113 770.043L273.028 769.644L273.185 766.911L266.389 766.526L266.533 763.727L259.605 763.329Z" fill="black"/>
</g>
</g>
</g>
<defs>
<clipPath id="clip0_233_4">
<rect width="60.5627" height="112.934" fill="white" transform="matrix(0.664791 -0.74703 0.796823 0.604213 267 486.242)"/>
</clipPath>
</defs>
</svg>

    </div>
  );
}

export default IHome;
