import React, { useState, useEffect } from 'react';
import "./assets/scss/App.scss";
import { Routes, Route, Link } from "react-router-dom";
import Home from "./pages/Home";
import Product from "./pages/Product";
import About from "./pages/About";
import Modal from 'react-modal';
import "regenerator-runtime/runtime";
import CreateUser from "./components/createUser";
import './assets/css/set2.css';

Modal.setAppElement('#root');

const App = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);

  function scrollTop() {
    setMenuIsOpen(!menuIsOpen);
    document.documentElement.classList.remove("no-scroll");
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
  }

  function openModal() {
    setMenuIsOpen(false);
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  function toggleMenu() {
    setMenuIsOpen(!menuIsOpen);
  }

  const [isTop, setIsTop] = useState(true);

  const handleScroll = () => {
    const scrollPosition = window.pageYOffset;
    setIsTop(scrollPosition >= 100);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
    <header className={`main ${!isTop ? "" : "sticky"}`}>
        <Link to="/" onClick={scrollTop}><h1><img src="img/unlock.svg" alt="Unlock"/></h1></Link>
        <nav className={`${menuIsOpen ? 'open' : ''}`}>
          <ul>
            <li>
              <Link to="/" onClick={scrollTop}>Home</Link>
            </li>
            <li>
              <Link to="/product" onClick={scrollTop}>Product</Link>
            </li>
            <li>
              <Link to="/about" onClick={scrollTop}>About</Link>
            </li>
            <li>
              <a onClick={openModal} className="button">Try it</a>
            </li>
          </ul>
        </nav>

        <button id="hamburger" className={`${menuIsOpen ? 'open mobile' : ' mobile'}`} onClick={toggleMenu}>
           <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </button>
      </header>

      <Routes>
        <Route path="/" element={<Home openModal={openModal} scrollTop={scrollTop}/>} />
        <Route path="/product" element={<Product openModal={openModal} scrollTop={scrollTop}/>} />
        <Route path="/about" element={<About openModal={openModal} scrollTop={scrollTop}/>} />
      </Routes>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        onAfterOpen={afterOpenModal}
      >
        <button onClick={closeModal} className="hidden">close</button>
        <div>
          <img src="img/jetons.svg" alt=""/>
          <span className="txt">Add your email address <br/>and try the platform.</span>
          <CreateUser/>
        </div>
      </Modal>
    </>
  );
}

export default App;
