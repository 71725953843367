import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link } from "react-router-dom";
import Number from './Number.js'
// const a = await fetch('https://metalyde.com/wp/wp-json/wp/v2/pages/27')
// const ABOUT = await a.json()

class WhoWeAre extends Component {

  render() {
    return (
      <section className="rose wwa">
        <div className="c">
          <h1>Who <br/>We Are</h1>
          <p><b>Our team, the creators of <a href="https://metalyde.com" rel="nofollow" target='_blank' title="Metalyde">Metalyde Studio</a>, has designed various loyalty and acquisition campaigns for brands using web3 technologies to enhance their needs.</b></p>
          <p>The feedback from these campaigns enhanced our understanding of consumer expectations and future loyalty challenges.</p>
          <Link to="/about" className="learn">learn more</Link>
          <div className="cadenas">
            <img src="/img/svg/cadenas.svg" alt=""/>
          </div>
        </div>
        <div className="wway">
        <div className='cc'>
              <div className='n'><Number value="69"/>%</div>
                  <p>of consumers are more loyal to a company that provides a personalized <b>customer experience.</b></p>
                  <span>Salesforce Survey</span>
                </div>
          <div className="imgBtm">
            <img src="img/svg/diams-yellow.svg" alt="" />
          </div>
        </div>
      </section>

    );
  }

}

export default WhoWeAre;
