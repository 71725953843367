import React, { Component } from 'react';
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Pagination, Navigation } from "swiper";
import "swiper/css/pagination";
import "swiper/css/navigation";

import Jackpot from '../illustrations/Jackpot.js'
import Rocket from '../illustrations/Rocket.js'
import Data from '../illustrations/Data.js'

import VisibilitySensor from 'react-visibility-sensor';

class Slider extends Component {
    constructor(props) {
      super(props);
      this.state = {
        activeSlide: 1,
        swiper: null,
        mouseWheelEnabled: false,
        isOnSlider: false,
      };
      this.sliderRef = React.createRef();
      this.rewardsRef = React.createRef();
      this.dataRef = React.createRef();
      // this.scrollTop = this.scrollTop.bind(this);
    }
  
    handleOnSwiper = (swiper) => {
      swiper.on("slideChange", (e) => {
        const activeSlideIndex = swiper.activeIndex;
        console.log(activeSlideIndex)
        switch (activeSlideIndex) {
          case 0:
            // swiper.mousewheel.disable();
            document.getElementById('prev').classList.remove("white");
            document.getElementById('next').classList.remove("white");
            break;
          case 1:
          //  swiper.mousewheel.enable();
          document.getElementById('prev').classList.add("white");
          document.getElementById('next').classList.add("white");

            break;
          case 2:
          //  swiper.mousewheel.disable();
          document.getElementById('prev').classList.remove("white");
          document.getElementById('next').classList.remove("white");

            break;
        }
        this.setState({
          activeSlide: activeSlideIndex + 1
        });
      });
    };
  
    handleVisibilityChange = (isVisible) => {
      const element = document.getElementById("slideContainer");
      const windowHeight = window.innerHeight;
  
      if (isVisible) {
        const rect = element.getBoundingClientRect();
        const height = rect.height;
        const top = rect.top;
        const bottom = rect.bottom;
        let percentageVisible;
  
        if (top < 0) {
          percentageVisible = Math.max(0, (bottom / height) * 100);
        } else if (bottom > windowHeight) {
          percentageVisible = Math.max(0, ((windowHeight - top) / height) * 100);
        } else {
          percentageVisible = 100;
        }

        if(percentageVisible < 40) {
            this.setState({ isOnSlider: false})
            console.log("isOnSlider", this.state.isOnSlider)
        }

        if(percentageVisible > 60) {
            this.scrollToTop();
        }
      }
    };
  
    scrollToTop = () => {
        if (!this.state.isOnSlider) {
          this.setState({ isOnSlider: true });
            const rect = document.getElementById('slideContainer').getBoundingClientRect();
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const elementTop = rect.top + scrollTop;
          window.scrollTo({
            top: elementTop,
            behavior: "smooth",
          });
          // this.sliderRef.current.swiper.mousewheel.enable();

        }
      };
  
    componentDidMount() {
      window.addEventListener("scroll", this.handleVisibilityChange); // add scroll event listener
    }
  
    componentWillUnmount() {
      window.removeEventListener("scroll", this.handleVisibilityChange); // remove scroll event listener
    }
  
    render() {
      const { activeSlide, swiper, mouseWheelEnabled } = this.state;

    return (
        <div id="slideContainer">
      <Swiper
      id="slider"
        direction={"horizontal"}
        mousewheel={false}
        speed={600}
        pagination={{
          el: ".swiper-pagination"
        }}
        navigation= {{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        modules={[Pagination, Navigation]}
        spaceBetween={0}
        slidesPerView={1}
        onSwiper={(swiper) => this.handleOnSwiper(swiper)}
        ref={this.sliderRef}

        // direction={"horizontal"}
        // mousewheel={false}
        // mousewheelControl={true}
        // mousewheelForceToAxis={true}
        // speed={600}
        // pagination={{
        //   el: ".swiper-pagination"
        // }}
        // navigation={false}
        // modules={[Pagination, Mousewheel]}
        // spaceBetween={0}
        // slidesPerView={1}
        // onSwiper={(swiper) => this.handleOnSwiper(swiper)}
        // ref={this.sliderRef}
      >
        <SwiperSlide id="rewards" className="rose">
                  <div className="c">
                  <div className="swipertxt">
                    <h1 className="big">Rew<br className="mobile"/>ards</h1>
                    <p><b>Reward your members <br/>with exclusives perks</b></p>
                    <p>Manage your program in a personalized manner. Create your own unique universe and offer exclusive rewards. Invite your members to events, unlock products, and acknowledge true loyalty.</p>
                    <Link to="/product" className="learn">learn more</Link>
                  </div>
                </div>
                <Jackpot/>
              </SwiperSlide>
               <SwiperSlide className="bleu">
                <div className="c">
                  <div className="swipertxt">
                    <h1 className="big">Imme<br className="mobile"/>rsive</h1>
                    <p><b>Engage your community <br/>and cultivate a unique bond <br/>with them</b></p>
                    <p>Place your customers back at the core of your strategy. Digital assets foster a distinct sense of belonging. Interact, provide exclusive content, communicate directly with your members, and empower your customers to have a say.</p>
                    <Link to="/product" className="learn">learn more</Link>
                  </div>
                </div>
                <Rocket/>
              </SwiperSlide>
               <SwiperSlide className="beige">
                <div className="c">
                  <div className="swipertxt">
                   <h1 className="big">Data</h1>
                    <p><b>Track and analyze your data<br/>
                    (to make better decision)</b></p>
                    <p>Deliver a personalized customer journey and meaningful benefits. Obtain data for each member, cross-reference their interests, and analyze using our dashboards to drive data-informed decisions.</p>
                    <Link to="/product" className="learn">learn more</Link>
                  </div>
                </div>
                <Data/>
              </SwiperSlide>
             </Swiper>
            <div className="swiper-pagination"></div>
            <div id="next" className="swiper-button-next"></div>
            <div id="prev" className="swiper-button-prev"></div>

            </div>
    )
}

}

export default Slider;
