import React, { Component, useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { db } from "../utils/firebase";
import { onValue, ref } from "firebase/database";
import { getDatabase, push } from "firebase/database";

function CreateUser() {
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const query = ref(db, "demoRequest");
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
    });
  }, []);

  const handleEmailChange = (event) => {
    const email = event.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    setIsValidEmail(isValidEmail);
  }

  const handleSubscribe = async () => {
    const emailInput = document.querySelector('#enterInput');
    const email = emailInput.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
  
    if (isValidEmail) {
      setIsLoading(true);
  
      const db = getDatabase();
      const demoRequestRef = ref(db, 'demoRequest/');
      try {
        const newDemoRequestRef = await push(demoRequestRef, { email });
        toast.success('Subscription successful!', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        document.querySelector('.ReactModal__Overlay').click();
      } catch (error) {
        console.log(error);
        toast.error('Failed to add email.', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
  
      setIsLoading(false);
    }
  }

  return (
    <div className="createUserForm">
        <input className="input__field input__field--ruri" type="text" id="enterInput" onChange={handleEmailChange} />
        <div id="enterAction">
          <button id="enterButton" onClick={handleSubscribe} disabled={!isValidEmail || isLoading} className={isValidEmail ? 'active' : 'disabled'}>
            {isLoading ? 'Loading...' : 'Enter'}
          </button>
        </div>
    </div>
  );
}

export default CreateUser;
