import React, { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function Nuages() {
  const wrapperRef = useRef(null);

  // useEffect(() => {
  //   const rotateOnScroll = () => {
  //     const element = wrapperRef.current;
  //     const height = element.clientHeight;
  //     const center = height / 2;

  //     gsap.to(element, {
  //       rotate: 180,
  //       transformOrigin: `50% ${center}px`,
  //       duration: 1,
  //       ease: "power2.out",
  //       scrollTrigger: {
  //         trigger: wrapperRef.current,
  //         start: "top center",
  //         end: "bottom center",
  //         scrub: true,
  //       },
  //     });
  //   };

  //   rotateOnScroll();
  // }, []);

  return (
          <div id="nuages" >
            <img src="./img/nuages.svg" ref={wrapperRef} className='rotate'/>
            <img src="./img/nuages-2.svg" ref={wrapperRef} className='rotate-inverse'/>

          </div>
  );
}

export default Nuages;